const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm9a-mc-four-things',
		text: 'Hvilke fire ting er der særligt fokus på ude på slagterierne når der arbejdes med arbejdsmiljø?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Psykisk arbejdsmiljø, støj, arbejdsulykker og ergonomi',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Psykisk arbejdsmiljø, støj, arbejdsudstyr og ernæring',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Ergonomi, ernæring, støj og arbejdsulykker',
			}),
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm9a-match-psychic',
		text: 'Hvad er psykisk arbejdsmiljø?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [2, 3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Et samspil mellem psykologiske og sociale forhold på arbejdspladsen og de enkelte medarbejdere.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Det mentale helbred hos medarbejderen og hvordan arbejdspladsen kan tilgodese de behov som medarbejderen har.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Et samspil mellem psykologiske og fysiske forhold på arbejdspladsen og de enkelte medarbejdere.',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm9a-mc-ergonomics',
		text: 'Hvordan arbejdes der med ergonomi ude på slagterierne?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Medarbejderne arbejder færre timer af gangen, så de undgår for høj belastning',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Psykomotoriske terapeuter der vurderer og rådgiver medarbejderne',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Rotation i arbejdsopgaver for at mindske belastningen på medarbejderne.',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Automatisering af belastende arbejdsopgaver',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Medarbejderne skifter arbejdsopgave/station hver halve time',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm9a-mc-why-ergonomics',
		text: 'Hvorfor arbejder man med ergonomi på slagterierne?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'For at sikre minimalt slid på medarbejderne og deres helbred',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'For at sikre, at medarbejderne kan arbejde hurtigere',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'For at sikre, at virksomheden bibeholder arbejdskraften',
				isCorrect: true
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}