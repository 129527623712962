const themesData = [
  {
    id: 'theme-1',
    title: 'Gris på tallerkenen',
  },
  {
    id: 'theme-2',
    title: 'Værdikæde'
  },
  {
    id: 'theme-3',
    title: 'Bæredygtighed'
  },
  {
    id: 'theme-4',
    title: 'Jobs'
  },
  {
    id: 'theme-5',
    title: 'Grisen – et verdens dyr'
  },
  {
    id: 'theme-6',
    title: 'Hygiejne'
  },
  {
    id: 'theme-7',
    title: 'Teknologi'
  },
  {
    id: 'theme-8',
    title: 'Fødevaresikkerhed'
  },
  {
    id: 'theme-9',
    title: 'Arbejdsmiljø'
  },
  {
    id: 'theme-10',
    title: 'Fremtidens landbrug'
  }
];


export {
	themesData
}