const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm10c-match-eco-friendly-animals',
		text: 'Hvordan kan forskning i genetik sikrer mere klimavenlige dyr?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Det vil styrke dyrenes foderudnyttelse og helbred.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Det gør det muligt at selektere de genetiske egenskaber, som vil styrke dyrenes robusthed og produktionsevne.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Det kan nedsætte mængden af lys som grisen reflekterer og dermed mindske varmeforbruget.',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm10c-mc-circular-bio-economy',
		text: 'Hvordan kan cirkulær bioøkonomi bidrage til mere bærdygtig fødevareproduktion?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Det kan være med til at  producere mere bæredygtigt.',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Det er med til at sikre en bedre udnyttelse af ressourcer.',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Det er med til at mindske behovet for gødning',
			}),
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm10c-match-food-industry',
		text: 'Hvorfor kan økologi bidrage til en mere bæredygtig fødevareindustri?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2, 3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: []
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Der ikke gives antibiotika til økologiske dyr, hvilket mindsker risikoen for udvikling af resistens hos bakterier.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Økologiske landmænd har flere græsmarker, der er gode til at samle CO2 fra atmosfæren.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Økologiske landmænd bruger ikke energikrævende hjælpestoffer.',
			})
		],
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}