import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {renderMarkdown} from 'helpers/text-helper';
import { sortArrayByProperty } from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import FacilitatorNewGamePopup from './facilitator-new-game-popup';
import './facilitator-games.scss';

const FacilitatorGames = (props) => {
	/* Props */
	const {showNewGamePopup, games, players, modules, setSelectedGameId, setShowNewGamePopup, createNewGame} = props;

	sortArrayByProperty(games, 'created', 'DESC');
	return (
		<div className="FacilitatorGames">
			{/* List of games */}
			<div className="FacilitatorGames-games">
				{games.map((game) => {
					const gamePlayers = players.filter((p) => {
						return (p.gameId === game.id && p.allTasksAreCompleted === true);
					});
					return (
						<div 
							key={game.id} 
							className="FacilitatorGames-game"
							onClick={() => {setSelectedGameId(game.id);}}
						>
							<div className="FacilitatorGames-gameTitleAndDescription">
								<div className="FacilitatorGames-gameTitle">
									<span>{game.title}</span>
								</div>
								<div className="FacilitatorGames-gameDescription">
									{game.description &&  renderMarkdown(game.description)}
								</div>
							</div>
							<div className="FacilitatorGames-playthroughs">
								<span>{getText('facilitatorUiTexts', 'numberOfPlaythroughs')}:</span>
								<span>{gamePlayers.length}</span>
							</div>
						</div>
					);
				})}
			</div>

			{/* New game btn */}
			<div className="FacilitatorGames-newGameBtn">
				<Button 
					classes={['facilitator']}
					text={getText('facilitatorUiTexts', 'newGame')}
					onClick={() => {setShowNewGamePopup(true);}}
				/>
			</div>

			{/* New game popup */}
			{showNewGamePopup && 
				<FacilitatorNewGamePopup 
					setShowNewGamePopup={setShowNewGamePopup}
					createNewGame={createNewGame}
					setSelectedGameId={setSelectedGameId}
					modules={modules}
				/>
			}
		</div>
	);
};

FacilitatorGames.propTypes = {
	showNewGamePopup: PropTypes.bool.isRequired,
	games: PropTypes.array.isRequired,
	players: PropTypes.array.isRequired,
	modules: PropTypes.array.isRequired,
	setSelectedGameId: PropTypes.func.isRequired,
	setShowNewGamePopup: PropTypes.func.isRequired,
	createNewGame: PropTypes.func.isRequired,
};

export default FacilitatorGames;