const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2b-mc-hygiene',
		text: 'Hvilke tre faktorer er afgørende for at sikre, at forbrugerne ikke bliver syge?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Høj hygiejne',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'God fødevarekontrol',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Overholdelse af Code of Practice',
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Skrappe veterinære regler både hos landmanden og på slagterierne',
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm2b-match-controls',
		text: 'Hvorfor er der veterinærkontrol både på gårdene og på slagterierne?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Det er en del af et overvågnings- og bekæmpelsesprogram for alvorlige smitsomme husdyrsygdomme',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Det er krav fra kunder i udlandet, og hvis der ikke er kontrol, kan kødet ikke sælges',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Historisk set var det et krav fra kongen at dyrehold skulle kontrolleres.',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2b-mc-quality',
		text: 'Hvordan sikrer slagterierne at fødevaresikkerheden er høj allerede ude ved landmændene?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Der er en repræsentant fra slagteriet ude ved hver landmand, der tjekker om alt foregår efter reglerne.',
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Landmændene skal overholde slagteriets Code of Practice for at levere dyr til slagteriet',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Gennem overholdse af skrappe veterinære regler både hos landmanden og på slagterierne',
				isCorrect: true
			})
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}