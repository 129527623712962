import React from 'react';
import PropTypes from 'prop-types';
import { welcomeMessage } from 'data/welcome-data';
import { renderMarkdown } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import Background from 'components/background/background';
import './landing-page.scss';

const LandingPage = ({cookiesAccepted, isSoundOn, startGame, setShowCookieBanner}) => {
	const handleStartGame = () => {
		if (cookiesAccepted) {
			startGame();
		} else {
			setShowCookieBanner(true);
		}
	};

	return (
		<div className='LandingPage'>
			<Background offsetMultiplier={0} isSoundOn={isSoundOn}/>
			<div className='LandingPage-welcomeFarmer'/>
			<div className='LandingPage-welcomeMessage'>
				{renderMarkdown(welcomeMessage)}
			</div>
			<div className='LandingPage-welcomeButton'>
				<Button 
					classes={['next']}
					onClick={() => {handleStartGame();}}
				/>
			</div>
			<div className="LandingPage-cookieBtn" onClick={() => {setShowCookieBanner(true);}}/>
		</div>
	);
};

LandingPage.propTypes = {
	cookiesAccepted: PropTypes.bool.isRequired,
	isSoundOn: PropTypes.bool.isRequired,
	startGame: PropTypes.func.isRequired,
	setShowCookieBanner: PropTypes.func.isRequired
};

export default LandingPage;