const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm10d-mc-circular-bioeconomy',
		text: 'Hvad er cirkulær bioøkonomi?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Cirkulær bioøkonomi er tanken om genanvendelse, bæredygtighed og økonomisk ansvarlighed i forhold til de ressourcer, vi er blevet tildelt.',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Cirkulær bioøkonomi er tanken om hvordan alle verdens lande fordeler jordens ressourcer',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm10d-mc-lca-calculations',
		text: 'Hvad er LCA-beregninger?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'LCA-beregning er livscyklusvurderinger, der beregner CO2-aftrykket på produkter.',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'LCA er livscyklusvurderinger der skaber gennemsigtighed i forhold til dyrevelfærden for det enkelte dyr.',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm10d-mc-soya-alternativ',
		text: 'Hvad testes lige nu som alternativ til soya i dyrefoder?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Bælgsæd',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Græsprotein',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Membranprotein',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm10d-mc-nitrate-food',
		text: 'Hvad bidrager brugen af nitrifikationshæmmere i foderkornsdyrkning til?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Det forhindrer ammoniak i at blive omdannet i jorden og dermed forurene grundvandet.',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Det nedsætter lugtgenerne ved udledningen af gødning.',
			})
		],
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}