const modulesData = [
  {
    id: 'module-1a',
    themeId: 'theme-1',
    title: 'Grisens liv - let',
    tasks: require('./module-1a-tasks').moduleTasks,
    avrTime: 61000
  },
  {
    id: 'module-1b',
    themeId: 'theme-1',
    title: 'Udskæringer fra grisen - let',
    tasks: require('./module-1b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-1c',
    themeId: 'theme-1',
    title: 'Detailhandel - let',
    tasks: require('./module-1c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-1d',
    themeId: 'theme-1',
    title: 'Grisens liv - svær',
    tasks: require('./module-1d-tasks').moduleTasks,
    avrTime: 50000
  },
  {
    id: 'module-1e',
    themeId: 'theme-1',
    title: 'Udskæringer fra grisen - svær',
    tasks: require('./module-1e-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-1f',
    themeId: 'theme-1',
    title: 'Detailhandel - svær',
    tasks: require('./module-1f-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-2a',
    themeId: 'theme-2',
    title: 'Værdikæde 1 - let',
    tasks: require('./module-2a-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-2b',
    themeId: 'theme-2',
    title: 'Værdikæde 2 - let',
    tasks: require('./module-2b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-2c',
    themeId: 'theme-2',
    title: 'Værdikæde 1 - svær',
    tasks: require('./module-2c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-2d',
    themeId: 'theme-2',
    title: 'Værdikæde 2 - svær',
    tasks: require('./module-2d-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-3a',
    themeId: 'theme-3',
    title: 'Bæredygtighed 1 - let',
    tasks: require('./module-3a-tasks').moduleTasks,
    avrTime: 106000
  },
  {
    id: 'module-3b',
    themeId: 'theme-3',
    title: 'Bæredygtighed 2 - let',
    tasks: require('./module-3b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-3c',
    themeId: 'theme-3',
    title: 'Bæredygtighed 1 - svær',
    tasks: require('./module-3c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-4a',
    themeId: 'theme-4',
    title: 'Tarmrenser - let',
    tasks: require('./module-4a-tasks').moduleTasks,
    avrTime: 94000
  },
  {
    id: 'module-4b',
    themeId: 'theme-4',
    title: 'Slagter - let',
    tasks: require('./module-4b-tasks').moduleTasks,
    avrTime: 85000
  },
  {
    id: 'module-4c',
    themeId: 'theme-4',
    title: 'Industrioperatør - let',
    tasks: require('./module-4c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-4d',
    themeId: 'theme-4',
    title: 'Tarmrenser - Svær',
    tasks: require('./module-4d-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-4e',
    themeId: 'theme-4',
    title: 'Slagter - svær',
    tasks: require('./module-4e-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-4f',
    themeId: 'theme-4',
    title: 'Industrioperatør - svær',
    tasks: require('./module-4f-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-5a',
    themeId: 'theme-5',
    title: 'Grisen – et verdens dyr 1 - Let',
    tasks: require('./module-5a-tasks').moduleTasks,
    avrTime: 71000
  },
  {
    id: 'module-5b',
    themeId: 'theme-5',
    title: 'Grisen – et verdens dyr 2 - Let',
    tasks: require('./module-5b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-5c',
    themeId: 'theme-5',
    title: 'Grisen – et verdens dyr 1 - Svær',
    tasks: require('./module-5c-tasks').moduleTasks,
    avrTime: 77000
  },
  {
    id: 'module-5d',
    themeId: 'theme-5',
    title: 'Grisen – et verdens dyr 2 - Svær',
    tasks: require('./module-5d-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-6a',
    themeId: 'theme-6',
    title: 'Hygiejne inden produktionen - let',
    tasks: require('./module-6a-tasks').moduleTasks,
    avrTime: 107000
  },
  {
    id: 'module-6b',
    themeId: 'theme-6',
    title: 'Hygiejne i produktionen - let',
    tasks: require('./module-6b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-6c',
    themeId: 'theme-6',
    title: 'Hygiejne inden produktionen - svær',
    tasks: require('./module-6c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-6d',
    themeId: 'theme-6',
    title: 'Hygiejne i produktionen - svær',
    tasks: require('./module-6d-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-7a',
    themeId: 'theme-7',
    title: 'Teknologi 1 - let',
    tasks: require('./module-7a-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-7b',
    themeId: 'theme-7',
    title: 'Teknologi 2 - let',
    tasks: require('./module-7b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-7c',
    themeId: 'theme-7',
    title: 'Teknologi 1 - svær',
    tasks: require('./module-7c-tasks').moduleTasks,
    avrTime: 62000
  },
  {
    id: 'module-7d',
    themeId: 'theme-7',
    title: 'Teknologi 2 - svær',
    tasks: require('./module-7d-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-8a',
    themeId: 'theme-8',
    title: 'Fødevaresikkerhed 1- let',
    tasks: require('./module-8a-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-8b',
    themeId: 'theme-8',
    title: 'Fødevaresikkerhed 2- let',
    tasks: require('./module-8b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-8c',
    themeId: 'theme-8',
    title: 'Fødevaresikkerhed 1- svær',
    tasks: require('./module-8c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-8d',
    themeId: 'theme-8',
    title: 'Fødevaresikkerhed 2- svær',
    tasks: require('./module-8d-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-9a',
    themeId: 'theme-9',
    title: 'Arbejdsmiljø 1- let',
    tasks: require('./module-9a-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-9b',
    themeId: 'theme-9',
    title: 'Arbejdsmiljø 2 - let',
    tasks: require('./module-9b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-9c',
    themeId: 'theme-9',
    title: 'Arbejdsmiljø 1 - svær',
    tasks: require('./module-9c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-10a',
    themeId: 'theme-10',
    title: 'Fremtidens landbrug 1 - let',
    tasks: require('./module-10a-tasks').moduleTasks,
    avrTime: 158000
  },
  {
    id: 'module-10b',
    themeId: 'theme-10',
    title: 'Fremtidens landbrug 2 - let',
    tasks: require('./module-10b-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-10c',
    themeId: 'theme-10',
    title: 'Fremtidens landbrug 1 - svær',
    tasks: require('./module-10c-tasks').moduleTasks,
    avrTime: 90000
  },
  {
    id: 'module-10d',
    themeId: 'theme-10',
    title: 'Fremtidens landbrug 2 - svær',
    tasks: require('./module-10d-tasks').moduleTasks,
    avrTime: 90000
  }
];


export {
	modulesData
}