const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2d-mc-code-of-practice',
		text: 'Hvad er Code of Practice?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Code of Practice definerer de minimumskrav, som Danish Crowns grise- og soandelsejere skal overholde for at levere dyr til Danish Crown.',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Code of Practice definerer det adfærdsregelsæt der er ude på slagterierne, som skal medarbejderne skal overholde for at sikre et godt arbejdsmiljø.',
			})
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm2d-match-quality',
		text: 'Hvordan sikres der en høj kvalitet af kød fra landmand til færdigt produkt?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: []
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Ved at have fokus på dyrevelfærd, bæredygtighed, hygiejne og fødevaresikkerhed sikres der god kvalitet i det færdige produkt',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Ved at have fokus på sygdomsbekæmpelse og skrappe krav til grisens foder',
			}),
		],
	}),
	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2d-mc-antibiotics',
		text: 'Hvorfor har den danske grisebranche skærpede krav til brugen af antibiotika?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Høje koncentrationer af antibiotika i kødet kan være skadeligt for forbrugerne og gøre dem resistente',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Høje koncentrationer af antibiotika kan ødelægge kødet og i smagen og gøre det sejt'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Det er krav fra internationale kunder, at der ikke må være høje koncentrationer af antibiotika i kødet',
			}),

		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm2d-match-true-false',
		text: 'Sandt eller falsk?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [2, 3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Der er veterinærkontroller, hvor dyrlæger ansat af staten overvåger hele processen fra gården til slagteriet',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Der er veterinærkontroller, hvor dyrlæger ansat af staten overvåger dyrene på gården, men der er ikke kontrol på slagteriet',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Der er veterinærkontroller, hvor dyrlæger er ansat af staten til inspektion dyrene på slagteriet, men der er ikke kontrol ude på gården',
			})
		],
	}),

];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}