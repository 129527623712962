import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import {spotErrorsMinusPoints} from 'data/points-data';
import './spot-errors.scss';

const SpotErrors = (props) => {
	const {
		playerTaskData, 
		taskData,
		handleCompleteTask,
		updateLoggedTime
	} = props;

	var timeout = null;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track available and selected options */
	const [spottedErrorIds, setSpottedErrorIds] = useState([]);
	const [numberOfMisses, setNumberOfMisses] = useState(0);

	/* Shake screen animation */
	const [shakeScreen, setShakeScreen] = useState(false);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSpottedErrorIds = () => {
		let errorIds = [];
		if (playerTaskData && playerTaskData.spottedErrorIds) {
			errorIds = playerTaskData.spottedErrorIds;
		}
		return errorIds;
	};

	/* New task: update status and spotted errors */
	useEffect(() => {
		setSpottedErrorIds(getSpottedErrorIds());
		setNumberOfMisses(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Component did mount / will unmount */
	useEffect(() => {
		return () => {
			if (timeout) clearTimeout(timeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Spot error
	 * @param {string} errorId 
	 * @returns 
	 */
	const spotErrorId = (errorId) => {
		/* Already completed */
		if (isCompleted === true) return;		

		/* Already spotted */
		if (spottedErrorIds.indexOf(errorId) >= 0) return;

		/* Update logged time */
		updateLoggedTime();
		
		/* Spot error */
		let newSpottedErrorIds = [...spottedErrorIds];
		newSpottedErrorIds.push(errorId);
		setSpottedErrorIds(newSpottedErrorIds);

		if (taskData.doneWhenAllErrorsSpotted && newSpottedErrorIds.length === taskData.errors.length) {
			/* Complete task automatically */
			completeTask();
		}
	};

	/**
	 * Click on background
	 */
	const backgroundClick = () => {
		/* Already completed */
		if (isCompleted === true) return;

		/* Shake screen */
		setShakeScreen(true);
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {setShakeScreen(false);}, 500);

		/* Update misses count */
		const newNumberOfMisses = numberOfMisses + 1;
		setNumberOfMisses(newNumberOfMisses);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Errors */ 
		const errors = numberOfMisses; // (numberOfErrors - spottedErrors);

		/* Calculate points */
		let points = spottedErrorIds.length;
		if (points > 0 && errors > 0) {
			const errorIndex = spotErrorsMinusPoints.errorLimits.findIndex((limit) => {return errors >= limit;});
			if (errorIndex >= 0) {
				const minusPoints = spotErrorsMinusPoints.pointValues[errorIndex];
				points = Math.max(spotErrorsMinusPoints.minPoints, points - minusPoints);
			}
		}
	
		/* Save completed task */
		handleCompleteTask(
			points, 
			errors,
			{spottedErrorIds: spottedErrorIds}
		);
	};

	return (
		<div className={'SpotErrors ' + taskData.layout 
			+ (isCompleted ?  ' completed' : '') + (shakeScreen ? ' shake' : '')}
		>
			<div className={'SpotErrors-errors ' + (taskData.image ? taskData.image : '')} 
				onClick={() => {backgroundClick();}}>
				{taskData.errors.map((error) => {
					return (
						<div 
							key={error.id} 
							className={'SpotErrors-error ' + error.id 
								+ (spottedErrorIds.indexOf(error.id) >= 0 ? ' spotted' : '')}
							onClick={(e) => {e.stopPropagation(); spotErrorId(error.id);}} 
						/>
					);
				})}
			</div>

			{taskData.text && <div className="SpotErrors-text">
				{renderMarkdown(taskData.text)}
			</div>}
			
			{(!isCompleted && !taskData.doneWhenAllErrorsSpotted) && 
				<div onClick={(e) => {e.stopPropagation(); completeTask();}} className="SpotErrors-confirmBtn">
					<span>{getText('gameUiTexts', 'ok')}</span></div>}
		</div>
	);
};

SpotErrors.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
};

export default SpotErrors;
