const imagesData = [
	'bird.svg',
	'farmer-high-five.png',
	'farmer.png',
	'next-button.svg',
	'pig-tail.svg',
	'speech-bubble-instructions.svg',
	
	// ICONS
	'icons/icon-arrow-down.svg',
	'icons/icon-arrow-up.svg',
	'icons/icon-checkmark.svg',
	'icons/icon-checkmark2.svg',
	'icons/icon-close.svg',
	'icons/icon-drag.svg',
	'icons/icon-error.svg',
	'icons/icon-loading.svg',
	'icons/icon-plus.svg',
	'icons/icon-pow.svg',
	'icons/icon-task-menu.svg',

	// PARALLAX
	'parallax/background.png',
	'parallax/foreground.png',
	'parallax/middleground.png',
	'parallax/tree.png',
];

export {
	imagesData
};
