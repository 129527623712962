const taskTemplates = require('data/templates/task-templates');

const welcomeData = [
	Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
	  id: 'welcome-1',
	  text: 'Velkommen til! Tag quizen og se om du kan slå high-scoren!',
	})
];

const welcomeMessage = `Hej med dig! Har du også nogen gange tænkt på, hvad verdensrekorden i baconspisning er? Eller hvor i verden de spiser grisetæer?
<br /><br />
Slå dig løs og test din viden i alverdens kategorier på grisen vej fra jord til bord.
<br /><br />
Klar til at spille?`

let taskIdsArr = [];
welcomeData.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});

export {
	welcomeData,
  welcomeMessage
}