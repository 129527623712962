import React from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import appConfig from 'config/app.config';
import { factsData } from 'data/facts-data';
import './facts.scss';

const Facts = ({selectedFactIds, toggleFact}) => {
	const infoText = getText('facilitatorUiTexts', 'didYouKnowInfo').replace('%numberOfFacts%', appConfig.gameFacts);

	return (
		<div className="Facts-factsWrap">
			<div className="Facts-factsTitle">
				<span>{getText('facilitatorUiTexts', 'didYouKnow')}</span>
			</div>
			<div className="Facts-factsText"><span>{infoText}</span></div>
			<div className="Facts-facts">
				{factsData.map((factData) => {
					const isSelected = selectedFactIds.indexOf(factData.id) >= 0;
					const isClickable = (isSelected || selectedFactIds.length < appConfig.gameFacts);
					return (
						<div 
							key={factData.id}
							className={'Facts-fact' 
								+ (isSelected ? ' selected' : '')
								+ (isClickable ? ' clickable' : '')}
							onClick={() => {toggleFact(factData.id);}}
						>
							<span></span>
							<span>{factData.text}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Facts.propTypes = {
	selectedFactIds: PropTypes.array.isRequired,
	toggleFact: PropTypes.func.isRequired,
};

export default Facts;