import appConfig from 'config/app.config';
import {getCombinedTaskTime} from './task-helper';
import {modulesData} from 'data/modules/modules-data';

/**
 * Attempts to get average time for module
 * This is based on all players in all games
 * @param {array} modules 
 * @param {array} moduleId
 * @returns average time in milliseconds or null if no completed times are found or if module data could not be found
 */
export function getModuleAverageTime(modules, moduleId) {
	/* Default avr time (on production server, we use data from demo server) */
	let avrTime = modulesData.find((moduleData) => {return moduleData.id === moduleId;})?.avrTime;

	/* Calculate actual avr time from data */ 
	const modulePlayTimeData = modules.find((m) => {return m.id === moduleId;});
	if (
		modulePlayTimeData && 
		modulePlayTimeData.playTimes && 
		modulePlayTimeData.playTimes.length > 0 &&
		(
			modulePlayTimeData.playTimes.length >= appConfig.moduleAvrTimePlayLimit ||
			appConfig.env !== 'production'
		)
	) {
		const totalTime = modulePlayTimeData.playTimes.reduce((partialSum, a) => {return partialSum + a;}, 0);
		avrTime = totalTime / modulePlayTimeData.playTimes.length;
	}

	return avrTime; 
};

/**
 * Get average playtime for a group of players
 * @param {array} players 
 * @param {array} moduleData
 * @returns average time in milliseconds or null if no completed times are found or if module data could not be found
 */
export function getAveragePlayTime(players) {
	let completeCount = 0;
	let sumTime = 0;

	const filteredPlayers = players.filter((player) => {
		return player.allTasksAreCompleted;
	});

	if (filteredPlayers.length > 0) {	
		// Go through all players
		filteredPlayers.forEach((player) => {
			completeCount ++;
			const combinedTime = getCombinedTaskTime(player.tasks);
			sumTime += combinedTime;
		});
		
		return sumTime / completeCount;
	}
	return null;
};

/**
 * Get time spent in module for a specific player
 * @param {object} playerData 
 * @param {string} moduleId 
 * @returns 
 */
export function getModulePlayTime(playerData, moduleId) {
	let playTime = 0;

	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	if (moduleData && moduleData.tasks && playerData.tasks) {
		let moduleIsCompleted = true;
		moduleData.tasks.forEach((taskData) => {
			if (!moduleIsCompleted) return;

			const playedTaskData = playerData.tasks.find((t) => {
				return t.taskId === taskData.id;
			});
			if (playedTaskData && playedTaskData.isCompleted) {
				playTime += playedTaskData.time;
			} else {
				moduleIsCompleted = false;
			}
		});
		if (!moduleIsCompleted) playTime = 0;
	}
	return playTime;
}


/**
 * Get total play time for each module in a game for a specific player
 * @param {object} gameData 
 * @param {object} playerData 
 * @returns 
 */
export function getPlayTimesForModulesInGame(gameData, playerData) {
	let modulePlayTimes = [];

	if (gameData && gameData.moduleIds && gameData.moduleIds.length > 0) {
		gameData.moduleIds.forEach((moduleId) => {
			const playTime = getModulePlayTime(playerData, moduleId);
			if (playTime > 0) {
				modulePlayTimes.push({
					moduleId: moduleId, 
					playTime: playTime
				});
			}
		});
	}

	return modulePlayTimes;
}