import PropTypes from 'prop-types';
import './player-points.scss';

const PlayerPoints = ({playerData}) => {
	const points = (playerData.totalPoints ? playerData.totalPoints : 0);

	return (
		<div className="PlayerPoints">
			<div className="PlayerPoints-wrap">
				<div className="PlayerPoints-points">
					<span>{points}</span>
				</div>
			</div>
		</div>
	);
};

PlayerPoints.propTypes = {
	playerData: PropTypes.object.isRequired,
};

export default PlayerPoints;