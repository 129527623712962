import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/text-helper';
import {getMaxPointsOfGame} from 'helpers/game-helper';
import Button from 'components/ui/button/button';
import './game-over.scss';

const GameOver = ({gameData, playerData, handleGoToNextTask, setStartGame}) => {
	const farmerInLength = 1; // seconds
	const farmerOutLength = 0.25; // seconds
	const textBoxFadeLength = 0.25; // seconds
	const screenWidth = window.screen.width;

	/* Next button delay */
	const [nextButtonDelay, setNextButtonDelay] = useState(null);

	/**
	 * Animate farmer when going to next task
	 */
	const handleNextButton = () => {
		if (gameData.hasHighscore) {
			const delay = setTimeout(function() {
				handleGoToNextTask();
			}, 1000);
			setNextButtonDelay(delay);
		} else {
			const delay = setTimeout(function() {
				setStartGame(false);
			}, 1000);
			setNextButtonDelay(delay);
		}
	};

	/**
	 * Component will unmount
	 */
	useEffect(() => {
		return () => {
			if (nextButtonDelay) {
				clearTimeout(nextButtonDelay);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Get text */
	let text = JSON.parse(JSON.stringify(getText('gameUiTexts', 'gameOver1')));
	let playerPoints = null;
	let maxPoints = null;
	if (playerData && playerData.hasOwnProperty('totalPoints')) {
		playerPoints = playerData.totalPoints;
	}
	if (gameData) {
		maxPoints = getMaxPointsOfGame(gameData, playerData);
	}
	if (maxPoints && playerPoints) {
		const pointPercentage = playerPoints / maxPoints * 100;
		if (pointPercentage <= 33) {
			text = JSON.parse(JSON.stringify(getText('gameUiTexts', 'gameOver3')));
		} else if (pointPercentage <= 66) {
			text = JSON.parse(JSON.stringify(getText('gameUiTexts', 'gameOver2')));
		} else if (pointPercentage <= 100) {
			text = JSON.parse(JSON.stringify(getText('gameUiTexts', 'gameOver1')));
		}
		text = text.replace('%totalPoints%', playerPoints);
		text = text.replace('%maxPoints%', maxPoints);
	}

	return (
		<div className="GameOver">
			<div className={'GameOver-farmer ' + (nextButtonDelay ? 'leaving' : '')}
				style={{
					animationDuration: nextButtonDelay ? 
						(screenWidth / appConfig.screenBaseWidth * farmerOutLength) + 's'
						: 
						(screenWidth / appConfig.screenBaseWidth * farmerInLength) + 's',
				}}
			>
			</div>
			<div className={'GameOver-content ' + (nextButtonDelay ? 'fade' : '')}
				style={{
					animationDuration: (screenWidth / appConfig.screenBaseWidth * textBoxFadeLength) + 's',
					animationDelay: nextButtonDelay ?
						'0s'
						:
						(screenWidth / appConfig.screenBaseWidth * farmerInLength) + 's',
				}}
			>
				<div className="GameOver-text">
					{renderMarkdown(text)}
				</div>
			</div>
			
			<div className="GameOver-nextBtn">
				{gameData.hasHighscore ? 
					<Button 
						classes={['next']}
						onClick={() => {handleNextButton();}}
					/>
					:
					<Button
						text={getText('facilitatorUiTexts', 'finish')}
						classes={['blue']}
						onClick={() => {handleNextButton();}}
					/>
				}
			</div>
		</div>
	);
};

GameOver.propTypes = {
	gameData: PropTypes.object.isRequired,
	playerData: PropTypes.object,
	handleGoToNextTask: PropTypes.func.isRequired,
	setStartGame: PropTypes.func.isRequired,
};

export default GameOver;
