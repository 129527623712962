const taskTemplates = require('data/templates/task-templates');

const factsData = [
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-1',
    title: 'Vidste du...',
    text: `Verdensrekorden i at spise bacon på 5 min. er 182 skiver.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-2',
    title: 'Vidste du...',
    text: `At den internationale bacon dag er den 4. september.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-3',
    title: 'Vidste du...',
    text: `At Danmark har det højeste forbrug af bacon per indbygger.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-4',
    title: 'Vidste du...',
    text: `At alle danskere i gennemsnit spiser mere end 100 skiver bacon og 60 pølser om året.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-5',
    title: 'Vidste du...',
    text: `Når du bestiller en "nissearm" i pølsevognen, får du serveret en rød pølse.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-6',
    title: 'Vidste du...',
    text: `Der i Danmark produceres ca. 434.000 km tarme årligt. Dvs. de kan nå mere end 10 gange rundt om jorden.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-7',
    title: 'Vidste du...',
    text: `I Danmark er den mest anvendte kødpålægstype i madpakken leverpostej.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-8',
    title: 'Vidste du...',
    text: `Ca. 190.000 mennesker i Danmark har et job i jordbrugs- og fødevarebranchen.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-9',
    title: 'Vidste du...',
    text: `En gris vejer cirka 110 kg, når den bliver slagtet.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-10',
    title: 'Vidste du...',
    text: `"Økologi" kommer fra græsk og betyder "Læren om naturens husholdning".`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-11',
    title: 'Vidste du...',
    text: `Grisetæer er en populær snack blandt asiatiske kvinder, da de indeholder meget kollagen, der giver smuk hud.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-12',
    title: 'Vidste du...',
    text: `I Kina er griseører en populær snack. Ørene koges i bouillon og skæres i tynde strimler.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-13',
    title: 'Vidste du...',
    text: `På en række områder er den danske lovgivning om dyrevelfærd mere vidtgående end EU’s krav.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-14',
    title: 'Vidste du...',
    text: `Madspild er dyrt. Beregninger viser, at madspild koster en typisk dansk familie ca. 10.000 kr. om året.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-15',
    title: 'Vidste du...',
    text: `73 % af danskerne bruger mindre end 30 min. på at tilberede deres aftensmad.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-16',
    title: 'Vidste du...',
    text: `Forbrugerarketypen "De umiddelbare" har en lav interesse for madlavning, og bruger i gennemsnit 5000 kr. mindre på mad om året end gennemsnittet.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-17',
    title: 'Vidste du...',
    text: `De som tilhører "De grønne" som forbrugerarketype laver på en almindelig uge 65% af de grønne vegetarretter minimum en gang.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-18',
    title: 'Vidste du...',
    text: `I Asien, bliver JAKA boven anset for at være et eksklusivt kvalitetsprodukt, som ofte gives i værtindegave.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-19',
    title: 'Vidste du...',
    text: `De 5 mest populære kødpålægsprodukter blandt danskerne er leverpostejen, spegepølsen, hamburgerryggen, rullepølsen og kylling/kalkun.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-20',
    title: 'Vidste du...',
    text: `Den første pølsevogn i Danmark åbnede tilbage i januar 1921.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-21',
    title: 'Vidste du...',
    text: `At frikadellen første gang nævnes i kogebogen "En højfornemme Madames" i år 1703.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-22',
    title: 'Vidste du...',
    text: `At grisen ikke kan svede, kun gispe og halse som en hund, når den skal af med varmen. Det er derfor den elsker at rulle sig i mudder.`
  }),
  Object.assign({}, taskTemplates.factTemplate.mainTemplate, {
    id: 'fact-23',
    title: 'Vidste du...',
    text: `At grisen er drægtig (gravid) i 3 måneder, 3 uger og 3 dage.`
  }),
];


export {
	factsData
}