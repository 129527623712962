const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm8a-match-polution-problem',
		text: 'På slagteriet kan produkterne blive forurenet på forskellige måder. Hvorfor er forurening et problem?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2, 3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [4]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Det kan være farligt',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Det kan ødelægge smagen',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Det kan give utilfredse kunder',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Det udleder mere CO2',
			})
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm8a-match-food-safety',
		text: 'Hvordan holdes der øje med fødevaresikkerheden ude på slagterierne?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Gennem forskellige fødevarekontroller',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Gennem kødsaftsprøver hvor der testes for salmonella',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Gennem evalueringer med medarbejderne i produktionen',
			})
		],
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}