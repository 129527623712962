import {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import firebaseInit from 'firebase-init';
import appConfig from 'config/app.config';
import {getOrientation, getAspectRatio} from 'helpers/device-helper';
import {getCookie, setCookie, deleteCookie} from 'helpers/cookie-helper';
import LandingPage from './landing-page/landing-page';
import HighscorePage from './highscore-page/highscore-page';
import FacilitatorRoute from './facilitator/facilitator-route';
import ImageLoader from './ui/image-loader/image-loader';
import PlayerRoute from './player/player-route';
import CookieBanner from './ui/cookie-banner/cookie-banner';
import './app.scss';

const App = () => {
	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {orientation: getOrientation(), aspectRatio: getAspectRatio()};
	});

	/* Cookies */
	const [showCookieBanner, setShowCookieBanner] = useState(false);
	const [cookiesAccepted, setCookiesAccepted] = useState(false);

	/* Set sound on/off */
	const [isSoundOn, setIsSoundOn] = useState(false);

	/* Goes from landing page to player route */
	const [playerGameStarted, setPlayerGameStarted] = useState(false);

	/**
	 * Accept / deny cookies
	 * @param {bool} isAccepted 
	 */
	const toggleAcceptCookies = (isAccepted) => {
		/* Update cookie(s) */
		if (isAccepted) {
			setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
		} else {
			deleteCookie(appConfig.cookiesAcceptedCookieName);
			deleteCookie(appConfig.playerIdCookieName);
		}

		/* Update states */
		setCookiesAccepted(isAccepted);
		setShowCookieBanner(false);
	};

	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		setDeviceInfo({orientation: getOrientation(), aspectRatio: getAspectRatio()});
	};

	/* Scroll to top */
	const scrollToTop = () => {
		const AppDiv = document.getElementById('App');
		if (AppDiv) AppDiv.scrollTop = 0;
	};


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */ 

		/* Initialize firebase */
		firebaseInit();

		/* Check cookie consent */
		const cookieData = getCookie(appConfig.cookiesAcceptedCookieName);
		if (cookieData && cookieData.length > 0) {
			setCookiesAccepted(true);
		} else {
			setShowCookieBanner(true);
		}

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Component will ummount */
		return () => {
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
		};
	}, []);

	return (
		<div id="App" className={'App ' + deviceInfo.orientation}>
			{/* Pre-load images */}
			<ImageLoader />
			<Router>
				<Routes>
					<Route
						path="/facilitator"
						element={<FacilitatorRoute />}
					/>
					<Route 
						path="/highscore/:gameId"
						element={<HighscorePage />}
					/>
					<Route
						path="/:gameId"
						element={(playerGameStarted && cookiesAccepted) ?
							<PlayerRoute 
								deviceInfo={deviceInfo} 
								scrollToTop={scrollToTop} 
								setStartGame={setPlayerGameStarted}
								isSoundOn={isSoundOn}
								setIsSoundOn={setIsSoundOn}
								setShowCookieBanner={setShowCookieBanner}
							/>
							:
							<LandingPage 
								startGame={() => {setPlayerGameStarted(true);}} 
								isSoundOn={isSoundOn}
								cookiesAccepted={cookiesAccepted}
								setShowCookieBanner={setShowCookieBanner}
							/>
						}
					/>
					<Route
						path="/"
						element={(playerGameStarted && cookiesAccepted) ?
							<PlayerRoute 
								deviceInfo={deviceInfo} 
								scrollToTop={scrollToTop} 
								setStartGame={setPlayerGameStarted}
								isSoundOn={isSoundOn}
								setIsSoundOn={setIsSoundOn}
								setShowCookieBanner={setShowCookieBanner}
							/>
							:
							<LandingPage 
								startGame={() => {setPlayerGameStarted(true);}} 
								isSoundOn={isSoundOn}
								cookiesAccepted={cookiesAccepted}
								setShowCookieBanner={setShowCookieBanner}
							/>
						}
					/>
				</Routes>
			</Router>

			{showCookieBanner && 
				<CookieBanner 
					toggleAcceptCookies={toggleAcceptCookies}
				/>
			}
		</div>
	);
};

export default App;
