import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import apiHelper from 'helpers/api-helper';
import Button from 'components/ui/button/button';
import Game from './game/game';
import './player.scss';

const Player = ({
	playerId, 
	gameData, 
	deviceInfo, 
	scrollToTop, 
	handleLogout, 
	handleBackgroundOffset, 
	setStartGame, 
	isSoundOn, 
	setIsSoundOn,
	setShowCookieBanner
}) => {
	/* Subscriptions */
	const unsubscribePlayerData = useRef(null);

	/* Loading status */
	const [isLoading, setIsLoading] = useState(true);

	/* Player data */
	const [playerData, setPlayerData] = useState(null);

	/**
	 * Component did mount / component will unmount
	 */
	useEffect(() => {
		/* Subscribe to player data */
		subscribeToPlayerData().then(() => {
			setIsLoading(false);
		});
		

		/* Component will unmount */
		return () => {
			/* Cancel player data subscription */
			if (unsubscribePlayerData.current) unsubscribePlayerData.current();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	/**
	 * Subscribe to game
	 * @returns {promise}
	 */
	const subscribeToPlayerData = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			unsubscribePlayerData.current = db.collection(appConfig.playersDbName).doc(playerId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Player exists, update player data state */
					const playerData = {id: doc.id, ...doc.data()};
					setPlayerData(playerData);
					resolve({ status: 'success'});
				} else {
					/* Player data does not exist (should not happen) */
					resolve({status: 'error', error: 'no-player'});
				}
			});
		});
	};

	/**
	 * Update player data
	 * @param {object} updates 
	 * @returns 
	 */
	const updatePlayerData = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update player data */
		const db = firebase.firestore();
		const userRef = db.collection(appConfig.playersDbName).doc(playerId);
		return userRef.update(updates);
	};


	/**
	 * Update module play times
	 * @param {array} modulePlayTimes 
	 */
	const updateModulePlayTimes = (modulePlayTimes) => {
		if (modulePlayTimes.length > 0) {

			return new Promise((resolve) => {
				apiHelper('player/update-module-play-times', {modulePlayTimes}).then((response) => {
					resolve(response);
				}).catch((error) => {
					console.error(error);
					resolve({status: 'error', error: error});
				});
			});
		}
	};


	/* Loading (subscribing to player data) */
	if (isLoading) {
		return (
			<div className="Player">
				loading
			</div>
		);
	}

	/* No player data */
	if (!playerData) {
		return (
			<div className="Player">
				{/* Logout btn */}
				<div className="Player-logoutBtn">
					<Button 
						text={getText('loginUiTexts', 'logout')}
						onClick={() => {handleLogout();}}
					/>
				</div>
				<div className="Player-text">
					<p>{getText('errorUiTexts', 'noUserData')}</p>
				</div>

			</div>
		);		
	}

	/* Player data ok, load game component */
	return (
		<div className={'Player ' + deviceInfo.orientation}>
			<Game 
				deviceInfo={deviceInfo}
				playerData={playerData}
				gameData={gameData}
				scrollToTop={scrollToTop}
				updatePlayerData={updatePlayerData}
				handleBackgroundOffset={handleBackgroundOffset}
				setStartGame={setStartGame}
				isSoundOn={isSoundOn}
				setIsSoundOn={setIsSoundOn}
				setShowCookieBanner={setShowCookieBanner}
				updateModulePlayTimes={updateModulePlayTimes}
			/>
		
		</div>
	);
};

Player.propTypes = {
	playerId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	handleBackgroundOffset: PropTypes.func.isRequired,
	setStartGame: PropTypes.func.isRequired,
	isSoundOn: PropTypes.bool.isRequired,
	setIsSoundOn: PropTypes.func.isRequired,
	setShowCookieBanner: PropTypes.func.isRequired,
};

export default Player;