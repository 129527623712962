import {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import ModuleChoice from '../module-choice/module-choice';
import Facts from '../facts/facts';
import './facilitator-new-game-popup.scss';

const FacilitatorNewGamePopup = ({setShowNewGamePopup, createNewGame, setSelectedGameId, modules}) => {
	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);

	/* Error msg */
	const [errMsg, setErrMsg] = useState(null);

	/* Part (1: name & desciption of game, 2: selected facts & modules) */
	const [partNumber, setPartNumber] = useState(1);

	/* Title  (part 1) */
	const [title, setTitle] = useState('');
	const editTitle = (event) => {
		setTitle(event.target.value);
		setErrMsg(null);
	};

	/* Description (part 2) */
	const [description, setDescription] = useState('');
	const editDescription = (event) => {
		setDescription(event.target.value);
		setErrMsg(null);
	};

	/* Selected facts (part 2) */
	const [selectedFactIds, setSelectedFactIds] = useState([]);

	/* Selected module ids  (part 2) */
	const [selectedModuleIds, setSelectedModuleIds] = useState([]);

	/* Selected theme id (part 2) */
	const [selectedThemeId, setSelectedThemeId] = useState(null);

	const [hasHighscore, setHasHighscore] = useState(true);

	/**
	 * Select / deselect fact
	 * @param {string} factId 
	 */
	const toggleFact = (factId) => {
		/* Reset err msg */
		setErrMsg(null);

		const factIndex = selectedFactIds.indexOf(factId);
		const newSelectedFactIds = JSON.parse(JSON.stringify(selectedFactIds));
		if (factIndex >= 0) {
			/* Deselect module */
			newSelectedFactIds.splice(factIndex, 1);
		} else {
			if (selectedFactIds.length < appConfig.gameFacts) {
				/* Select module */
				newSelectedFactIds.push(factId);
			} else {
				/* Max number of facts already selected */
				setErrMsg(getText('errorUiTexts', 'maxFacts'));
				return;
			}
		}
		setSelectedFactIds(newSelectedFactIds);
	};

	/**
	 * Select / deselect highscore page
	 */
	const toggleHighscore = () => {
		setHasHighscore(!hasHighscore);
	};

	/**
	 * Select / deselect module
	 * @param {string} moduleId 
	 */
	const toggleModule = (moduleId) => {
		/* Reset err msg */
		setErrMsg(null);

		const moduleIndex = selectedModuleIds.indexOf(moduleId);
		const newSelectedModuleIds = JSON.parse(JSON.stringify(selectedModuleIds));
		if (moduleIndex >= 0) {
			/* Deselect module */
			newSelectedModuleIds.splice(moduleIndex, 1);
		} else {
			/* Select module */
			newSelectedModuleIds.push(moduleId);
		}
		setSelectedModuleIds(newSelectedModuleIds);
	};

	/**
	 * Select / deselect theme
	 * @param {string} themeId 
	 */
	const toggleTheme = (themeId) => {
		if (themeId === selectedThemeId) {
			/* Deselect theme */
			setSelectedThemeId(null);
		} else {
			/* Select theme */
			setSelectedThemeId(themeId);
		}
	};

	/**
	 * Confirm current part
	 */
	const confirmPart = () => {
		if (isLoading) return;

		if (partNumber === 1) {
			/* Part 1 - check title and description */
			if (
				title && title.length > 0 &&
				description && description.length > 0
			) {
				/* Go to next part */
				setPartNumber(2);
			} else {
				/* Title and/or description not ok */
				setErrMsg(getText('errorUiTexts', 'emptyFields'));
			}
		} else if (partNumber === 2) {
			/* Part 2 - check number of selected modules */
			if (selectedModuleIds.length >= appConfig.gameMinModules) {
				/* Part 2 - check number of selected facts */
				if (selectedFactIds.length === appConfig.gameFacts) {
					/* Create new game */
					setIsLoading(true);
					createNewGame(title, description, selectedFactIds, selectedModuleIds, hasHighscore)
						.then((response) => {
							setIsLoading(false);
							if (response.status === 'success' && response.gameId) {
								setShowNewGamePopup(false);
								setSelectedGameId(response.gameId);
							} else {
								setErrMsg(getText('errorUiTexts', 'unknownError'));
							}
						});
				} else {
					/* Not enough facts selected */
					const errTxt = JSON.parse(JSON.stringify(getText('errorUiTexts', 'tooFewFacts')))
						.replace('%number%', appConfig.gameFacts);
					setErrMsg(errTxt);
				}
			} else {
				/* Not enough modules selected */
				const errTxt = JSON.parse(JSON.stringify(getText('errorUiTexts', 'tooFewModules')))
					.replace('%number%', appConfig.gameMinModules);
				setErrMsg(errTxt);
			}
		}
	};

	return (
		<div className="FacilitatorNewGamePopup">
			<div className="FacilitatorNewGamePopup-content">
				{/* Part 1: game title & description */}
				{partNumber === 1 &&
					<div className={'FacilitatorNewGamePopup-part part-1' + (partNumber === 1 ? ' show' : '')}>
						<div className="FacilitatorNewGamePopup-partTitle">
							<span>{getText('facilitatorUiTexts', 'newGame')}</span>
						</div>
						<div className="FacilitatorNewGamePopup-partContent">
							{/* Game title input */}
							<span>{getText('facilitatorUiTexts', 'nameOfGameLabel')}</span>
							<input 
								type="text"
								name="title"
								placeholder={getText('facilitatorUiTexts', 'nameOfGamePlaceholder')}
								value={title ? title : ''}
								onChange={(e) => {editTitle(e);}}
							/>
							{/* Game description input */}
							<span>{getText('facilitatorUiTexts', 'descriptionOfGameLabel')}</span>
							<textarea 
								name="description"
								placeholder={''}
								value={description ? description : ''}
								onChange={(e) => {editDescription(e);}}
							/>
						</div> 
					</div>
				}

				{/* Part 2: facts & modules */}
				{partNumber === 2 &&
					<div className={'FacilitatorNewGamePopup-part part-2' + (partNumber === 2 ? ' show' : '')}>
						{/* Title */}
						<div className="FacilitatorNewGamePopup-partTitle">
							<span>{title}</span>
						</div>
						{/* Description */}
						<div className="FacilitatorNewGamePopup-partSubTitle">
							<span>{description}</span>
						</div>
						<div className="FacilitatorNewGamePopup-partContent">
							{/* Facts */}
							<Facts selectedFactIds={selectedFactIds} toggleFact={toggleFact}/>

							{/* Modules */}
							<ModuleChoice 
								toggleTheme={toggleTheme}
								toggleModule={toggleModule}
								selectedModuleIds={selectedModuleIds}
								selectedThemeId={selectedThemeId}
								modules={modules}
							/>
						</div>

						<div className='FacilitatorNewGamePopup-toggleHighscore'>
							<div className='FacilitatorNewGamePopup-toggleHighscoreText'>
								<span>{getText('facilitatorUiTexts', 'toggleHighscore')}</span>
							</div>
							<div className={'FacilitatorNewGamePopup-toggle ' + (hasHighscore ? 'selected' : '')} 
								onClick={() => {toggleHighscore();}}
							/>
						</div>
					</div>
				}

				{/* Error message */}
				<div className="FacilitatorNewGamePopup-errMsg">
					<span>{errMsg}</span>
				</div>

				{/* Buttons */}
				<div className="FacilitatorNewGamePopup-buttons">
					{/* Confirm btn */}
					<div className="FacilitatorNewGamePopup-confirmBtn">
						<Button
							isLoading={isLoading}
							classes={['facilitator']}
							text={(partNumber === 1 
								? getText('facilitatorUiTexts', 'continue') 
								: getText('facilitatorUiTexts', 'createGame')
							)}
							onClick={() => {confirmPart();}}
						/>
					</div>
					<div className="FacilitatorNewGamePopup-cancelBtn">
						<Button
							classes={['facilitator']}
							text={getText('facilitatorUiTexts', 'cancel') }
							onClick={() => {setShowNewGamePopup(false);}}
						/>
					</div>				
				</div>
			</div>
		</div>
	);
};

FacilitatorNewGamePopup.propTypes = {
	setShowNewGamePopup: PropTypes.func.isRequired,
	createNewGame: PropTypes.func.isRequired,
	setSelectedGameId: PropTypes.func.isRequired,
	modules: PropTypes.array.isRequired
};

export default FacilitatorNewGamePopup;