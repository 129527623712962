import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import {getCookie, deleteCookie} from 'helpers/cookie-helper';
import { getText, renderMarkdown } from 'helpers/text-helper';
import Background from 'components/background/background';
import PlayerLogin from './login/player-login';
import Player from './player';
import './player-route.scss';

const PlayerRoute = ({deviceInfo, scrollToTop, setStartGame, isSoundOn, setIsSoundOn, setShowCookieBanner}) => {
	/* Game id (from router) */
	const {gameId} = useParams();

	/* Loading status */
	const [isLoading, setIsLoading] = useState(true);
	
	/* Game subscription and data*/
	const unsubscribeGame = useRef(null);
	const [gameData, setGameData] = useState(null);

	/* Player id */
	const [playerId, setPlayerId] = useState(null);

	/* Background position */
	const [backgroundOffsetMultiplier, setBackgroundOffsetMultiplier] = useState(1);

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Subscribe to game data */
		subscribeToGame().then((response) => {
			setIsLoading(false);
			if (response.status === 'success') {
				/* Game exists, Check if player is logged in */
				checkIfLoggedIn(response.gameData);
			}
		});
		
		/* Component will unmount */
		return () => {
			/* Cancel game subscription */
			if (unsubscribeGame.current) unsubscribeGame.current();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	/**
	 * Subscribe to game
	 * @returns {promise}
	 */
	const subscribeToGame = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			if (gameId) {
				const gameIdLowercase = gameId.toLowerCase();
				unsubscribeGame.current = db.collection(appConfig.gamesDbName)
					.doc(gameIdLowercase)
					.onSnapshot((doc) => {
						if (doc.exists) {
							/* Game exists, update game data state */
							const gameData = {id: doc.id, ...doc.data()};
							setGameData(gameData);
							resolve({ status: 'success', gameData });
						} else {
							/* Game does not exist */
							resolve({status: 'error', error: 'no-game'});
						}
					});
			} else {
				/* Game does not exist */
				resolve({status: 'error', error: 'no-game'});
			}
		});
	};

	/**
	 * Check if player is logged in
	 */
	const checkIfLoggedIn = () => {
		/* Get player id from cookie */
		const cookiePlayerId = getCookie(appConfig.playerIdCookieName);
		if (cookiePlayerId && cookiePlayerId.length > 0) {
			/* Check if player exists in db */
			const db = firebase.firestore();
			db.collection(appConfig.playersDbName).doc(cookiePlayerId).get().then((doc) => {
				if (doc.exists) {
					/* Player exists in db, check if game id matches url */
					if (doc.data().gameId === gameId) {
						/* Game matches, log in (set player id) */
						setPlayerId(cookiePlayerId);
					}
				}
			});
		}
	};
	
	/**
	 * Logout
	 */
	const handleLogout = () => {
		setPlayerId(null);
		deleteCookie(appConfig.playerIdCookieName);
	};

	/* Loading game data */
	if (isLoading) {
		return (
			<div className='PlayerRoute'>
				loading
			</div>
		);
	}

	/* URL has no corresponding game */
	if (!isLoading && !gameData) {
		return (
			<div className='PlayerRoute'>
				<Background 
					offsetMultiplier={0}
					isSoundOn={isSoundOn}
				/>
				<div className='PlayerRoute-noGameFarmer'/>
				{gameId ?
					<div className='PlayerRoute-noGameMessage'>
						{renderMarkdown(getText('loginUiTexts', 'noGameText'))}
					</div>
					:
					<div className='PlayerRoute-noGameMessage'>
						{renderMarkdown(getText('loginUiTexts', 'noGameIdText'))}
					</div>
				}
			</div>
		);
	}

	/**
	 * Move background offset by 1 step multiplier
	 */
	const handleBackgroundOffset = () => {
		setBackgroundOffsetMultiplier(backgroundOffsetMultiplier + 1);
	};

	/* Game exists, login or play */
	return (
		<div className='PlayerRoute'>
			<Background 
				offsetMultiplier={backgroundOffsetMultiplier}
				isSoundOn={isSoundOn}
			/>
			{playerId 
				? <Player 
					playerId={playerId} 
					gameData={gameData} 
					deviceInfo={deviceInfo} 
					scrollToTop={scrollToTop}
					handleLogout={handleLogout}
					handleBackgroundOffset={handleBackgroundOffset}
					setStartGame={setStartGame}
					isSoundOn={isSoundOn}
					setIsSoundOn={setIsSoundOn}
					setShowCookieBanner={setShowCookieBanner}
				/>
				: <PlayerLogin 
					gameId={gameId} 
					deviceInfo={deviceInfo} 
					setPlayerId={setPlayerId}
				/>
			}
		</div>
	);
};

PlayerRoute.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	setStartGame: PropTypes.func.isRequired,
	isSoundOn: PropTypes.bool.isRequired,
	setIsSoundOn: PropTypes.func.isRequired,
	setShowCookieBanner: PropTypes.func.isRequired
};

export default PlayerRoute;