const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		id: 'm6b-order-handwashing',
		text: `I hygiejnesluseren skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: `Vask hænder`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: `Tør hænder`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: `Smid håndklædepapir i skraldepose`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: `Brug håndsprit`,
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm6b-mc-hygiene-lock',
		text: 'Hvad er en hygiejnesluse?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Når mennesker skal fra én zone til en anden uden at bringe bakterier eller vira med ind.',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Til rengøring af maskiner i fødevareproduktioner, så der ikke kommer bakterier i produkterne',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Til at vaske grisene rene, når de kommer fra lastbilen og ind på slagteriet, så der ikke bringes bakterier med ind.',
			}),
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm6b-match-check-work-equipment',
		text: 'Hvorfor er det vigtigt at tjekke sit arbejdstøj og udstyr inden man går i gang med arbejde?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [2, 3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'For at undgå at der ikke sker forurening af produkter gennem fremmedlegmer fra tøj og udstyr.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'For at sikre, at medarbejderne ikke får skader eller forurener deres private tøj under arbejde',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'For at sikre, at der ikke sker ulykker ude i produktionen grundet fejl i udstyret.',
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm6b-match-work-home-clothes',
		text: 'Hvorfor er det vigtigt at holde privat tøj og arbejdstøj adskilt i omklædningsrummet?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [1, 3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'For at beskytte medarbejdernes private tøj mod snavs fra arbejdstøjet og dermed ikke får bakterier fra produktionen med hjem',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Der må ikke komme bakterier fra medarbejdernes private tøj overpå arbejdstøjet og med ind i produktionen',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'For at undgå at resistente bakterier udvikler sig i omklædningsrummet.',
			}),
		],
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}