import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {QRCodeSVG} from 'qrcode.react';
import {QRCodeCanvas} from 'qrcode.react';
import Button from '../button/button';
import './qr.scss';

const QR = ({url}) => {
	/**
	 * Downloads QR code as PNG file
	 */
	const downloadQRPng = () => {
		const element = document.getElementById('codePng');
		const pngUrl = element.toDataURL('image/png').replace('image/png', 'image/octet-stream');
		let downloadLink = document.createElement('a');
		downloadLink.href = pngUrl;
		downloadLink.download = 'code.png';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	/**
	 * Downloads QR code as SVG file
	 */
	const downloadQRSvg = () => {
		let svgData = document.getElementById('codeSvg').outerHTML;

		if (!svgData.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
			svgData = svgData.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
		}
		if (!svgData.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
			svgData = svgData.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
		}

		const svgBlob = new Blob([svgData], {type:'image/svg+xml;charset=utf-8'});
		const svgUrl = URL.createObjectURL(svgBlob);
		
		const downloadLink = document.createElement('a');
		downloadLink.href = svgUrl;
		downloadLink.download = 'code.svg';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	return (
		<div className="QR">
			<div className="QR-info">
				<div className="QR-title">
					<span>{getText('facilitatorUiTexts', 'qrCode')}</span>
				</div>
				<div className="QR-text">
					<span>{getText('facilitatorUiTexts', 'qrDownloadInfo')}</span>
				</div>
				<div className="QR-downloadBtn">
					<Button 
						classes={['facilitator']}
						text={getText('facilitatorUiTexts', 'downloadSvg')}
						onClick={() => {downloadQRSvg();}}
					/>
				</div>
				<div className="QR-downloadBtn">
					<Button 
						classes={['facilitator']}
						text={getText('facilitatorUiTexts', 'downloadPng')}
						onClick={() => {downloadQRPng();}}
					/>
				</div>
			</div>
			<div className="QR-code">
				<QRCodeSVG
					id={'codeSvg'}
					value={url}
					bgColor={'#FFFFFF'}
					fgColor={'#000000'}
				/>
				<QRCodeCanvas
					id={'codePng'}
					value={url}
					bgColor={'#FFFFFF'}
					fgColor={'#000000'}
				/>
			</div>
		</div>
	);
};

QR.propTypes = {
	url: PropTypes.string.isRequired
};

export default QR;