import dayjs from 'dayjs';

/**
 * Create / write to cookie
 * @param {string} cname 
 * @param {string} cvalue 
 */
function setCookie(cname, cvalue) {
	/* Default cookie duration is 1 year */
	let year = new Date();
	year.setTime(year.getTime() + (365 * 24 * 60 * 60 * 1000));
	let expires = 'expires=' + year.toUTCString();

	if (cname === 'mobil-gris-player-id') {
		/* Cookie expires at midnight */
		const todayNow = dayjs(new Date());
		const midnight = dayjs(todayNow).add(1, 'day').format('YYYY-MM-DD');

		expires = 'expires=' + dayjs(midnight).diff(todayNow, 'milliseconds');
	}

	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * Get cookie data
 * @param {string} cname 
 * @returns 
 */
function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

/**
 * Delete cookie
 * @param {string} cname 
 */
function deleteCookie(cname) {
	const year = new Date();
	year.setTime(year.getTime());
	const expires = 'expires=' + year.toUTCString();
	document.cookie = cname + '="";' + expires + ';path=/';
}

export {
	setCookie,
	getCookie,
	deleteCookie
};