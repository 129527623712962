import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './high-five.scss';

const HighFive = ({playerTaskData, taskData, handleGoToNextTask, handleCompleteTask}) => {
	const farmerInLength = 1; // seconds
	const farmerOutLength = 0.25; // seconds
	const textBoxFadeLength = 0.25; // seconds
	const screenWidth = window.screen.width;

	/* Check if task is completed */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true);

	/* Next button delay */
	const [nextButtonDelay, setNextButtonDelay] = useState(null);

	/* Show high-five animation */
	const [showHighFiveAnimation, setShowHighFiveAnimation] = useState(false);

	/**
 	 * Complete task
 	 */
	const completeTask = () => {
		if (!isCompleted) {
			/* Show high-five animation */
			setShowHighFiveAnimation(true);

			/* Save completed task */
			handleCompleteTask(
				0,
				0,
				null
			);
		}

	};

	/**
	 * Animate farmer when going to next task
	 */
	const handleNextButton = () => {
		const delay = setTimeout(function() {
			handleGoToNextTask();
		}, 1000);
		setNextButtonDelay(delay);
	};

	/**
	 * Component will unmount
	 */
	useEffect(() => {
		return () => {
			if (nextButtonDelay) {
				clearTimeout(nextButtonDelay);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	/* Get text (before / after high-five )*/
	const text = (isCompleted ? taskData.text2 : taskData.text);

	return (
		<div className={'HighFive ' + (taskData.background ? taskData.background : '')}>
			<div className={'HighFive-farmer ' + (nextButtonDelay ? 'leaving' : '')}
				style={{
					animationDuration: nextButtonDelay ? 
						(screenWidth / appConfig.screenBaseWidth * farmerOutLength) + 's'
						: 
						(screenWidth / appConfig.screenBaseWidth * farmerInLength) + 's',
				}}
			>
				<div 
					className={'HighFive-target' + (showHighFiveAnimation ? ' animate' : '')} 
					onClick={() => {completeTask();}}
				/>
			</div>
			{text && 
				<div className={'HighFive-content ' + (nextButtonDelay ? 'fade' : '')}
					style={{
						animationDuration: (screenWidth / appConfig.screenBaseWidth * textBoxFadeLength) + 's',
						animationDelay: nextButtonDelay ?
							'0s'
							:
							(screenWidth / appConfig.screenBaseWidth * farmerInLength) + 's',
					}}
				>
					<div className="HighFive-text">
						{renderMarkdown(text)}
					</div>
				</div>
			}
			{(isCompleted) && <div className="HighFive-nextBtn">
				<Button 
					classes={['next']}
					onClick={() => {handleNextButton();}}
				/>
			</div>}
		</div>
	);
};

HighFive.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleGoToNextTask: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default HighFive;
