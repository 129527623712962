const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm9b-match-risks',
		text: 'Hvilke faldrisici kan der være på et slagteri?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2, 3, 4, 6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [5]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Kød',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Vand',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Blod',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Tarme',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				text: 'Tøj',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				text: 'Fedt',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm9b-mc-avoid-risks',
		text: 'Hvordan undgås der faldrisicis på slagteriet?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Medarbejderne skal have skridsikkert fodtøj',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Rengøring af gulve under og efter endt arbejdsdag',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Oprydning under arbejde',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Skridsikkert underlag',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Sikkerhedspersonale der holder øje ude i produktionen',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Faldalarmer ude i produktionen',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm9b-mc-why-noise',
		text: 'Hvorfor er støj et problem for medarbejderne ude på slagterierne?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Støj kan give medarbejderne dårlig hørelse',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Støj forhindre medarbejderne i at kommunikere med hinanden',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Støj forstyrrer medarbejderne i deres arbejde',
			}),
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm9b-match-reduce-noise',
		text: 'Hvordan reduceres støj ude i produktionen?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2, 4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [3, 5]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Sikring af at leverandørerne af maskinerne til produktionen har fokus på maskinernes støjniveau',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Inddæmning af maskinerne, så de støjer mindre',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Færre maskiner ude i produktionen',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Medarbejderne får tildelt høreværn',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				text: 'Placering af maskinerne i separate rum i forhold til hvor medarbejderne er',
			})
		],
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}