import React from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import Button from '../button/button';
import './cookie-banner.scss';

const CookieBanner = ({toggleAcceptCookies}) => {
	return (
		<div className='CookieBanner'>
			<div className='CookieBanner-content'>
				<div className='CookieBanner-cookieText'>{renderMarkdown(getText('cookieUi', 'text'))}</div>
				<div className='CookieBanner-buttons'>
					<div className="CookieBanner-button no">
						<Button 
							text={getText('cookieUi', 'no')} 
							classes={['red']} 
							onClick={() => {toggleAcceptCookies(false);}} 
						/>
					</div>
					<div className="CookieBanner-button yes">
						<Button 
							text={getText('cookieUi', 'yes')} 
							classes={['blue']} 
							onClick={() => {toggleAcceptCookies(true);}} 
						/>
					</div>
				</div>
			</div>
			
		</div>
	);
};

CookieBanner.propTypes = {
	toggleAcceptCookies: PropTypes.func.isRequired,
};

export default CookieBanner;