import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Button from 'components/ui/button/button';
import { getText } from 'helpers/text-helper';
import './facilitator-login.scss';

const FacilitatorLogin = ({togglePasswordReset}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [feedback, setFeedback] = useState(null);

	/**
	 * Update email input field
	 * @param {obj} event 
	 */
	const handleEmailInput = (event) => {
		setFeedback(null);
		setEmail(event.target.value);
	};

	/**
	 * Update password input field
	 * @param {obj} event 
	 */
	const handlePasswordInput = (event) => {
		setFeedback(null);
		setPassword(event.target.value);
	};

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	const handleLogin = (event) => {
		if (event) {
			event.preventDefault();
		}

		setIsLoading(true);

		let newFeedback = null;
		if (!email || !password) {
			newFeedback = getText('errorUiTexts', 'emptyFields');
		}
		if (!newFeedback) {
			firebase.auth().signInWithEmailAndPassword(email, password)
				.catch((error) => {
					if (error.code === 'auth/user-not-found') {
						newFeedback = getText('errorUiTexts', 'emailNotFound');
					}
					if (error.code === 'auth/wrong-password') {
						newFeedback = getText('errorUiTexts', 'invalidEmailPasswordFormat');
					}
					if (error.code === 'auth/invalid-email') {
						newFeedback = getText('errorUiTexts', 'invalidEmail');
					}
					if (!newFeedback) {
						console.error(error);
						newFeedback = getText('errorUiTexts', 'unknownError');
					}
					setIsLoading(false);
					setFeedback(newFeedback);
				});
		} else {
			setIsLoading(false);
			setFeedback(newFeedback);
		}
	};

	return (
		<div className='FacilitatorLogin'>
			<form className='FacilitatorLogin-form'>
				<div className='FacilitatorLogin-title'>
					<span>{getText('loginUiTexts', 'login')}</span>
				</div>
				<div className='FacilitatorLogin-inputWrapper'>
					<span>{getText('loginUiTexts', 'email')}</span>
					<input
						className='FacilitatorLogin-input'
						name="email"
						type="text"
						placeholder={''}
						autoComplete="section-facilitatorlogin email"
						value={email ? email : ''}
						onChange={(event) => {handleEmailInput(event);}}
					/>
					<span>{getText('loginUiTexts', 'password')}</span>
					<input
						className='FacilitatorLogin-input'
						name="password"
						type="password"
						placeholder={''}
						autoComplete="section-facilitatorlogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handlePasswordInput(event);}}
					/>
				</div>
				<div className='FacilitatorLogin-loginButton'>
					<Button 
						isLoading={isLoading}
						classes={['facilitator']}
						text={getText('loginUiTexts', 'login')} 
						onClick={handleLogin}
					/>
				</div>
				<div className="FacilitatorLogin-errorMessage"><span>{feedback}</span></div>
				<div onClick={() => {togglePasswordReset();}} className='FacilitatorLogin-resetPassword'>
					<span>{getText('loginUiTexts', 'resetPassword')}</span>
				</div>
			</form>


		</div>
	);
};

FacilitatorLogin.propTypes = {
	togglePasswordReset: PropTypes.func.isRequired,
};

export default FacilitatorLogin;