let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let gamesDbName = 'games';
let playersDbName = 'players';
let modulesDbName = 'modules';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-mobil-gris-test.web.app';
	apiURL = 'https://cgl-mobil-gris-test.web.app/api/';
	if (env === 'demo') {
		gamesDbName = 'games-demo';
		playersDbName = 'players-demo';
		modulesDbName = 'modules-demo';
		gameUrl = 'cgl-mobil-gris-demo2.web.app';
		apiURL = 'https://cgl-mobil-gris-demo2.web.app/api/';
	}
}
if (env === 'production') {
	gameUrl = 'mobilgris.dk';
	apiURL = 'https://cgl-mobil-gris-production.web.app/api/';
}

const appConfig = {
	env,
	gameUrl,
	apiURL,
	showDevTools: (env === 'development' || env === 'test'),
	name: 'Mobil gris',
	cookiesAcceptedCookieName: 'mobil-gris_cookies_accepted',
	playerIdCookieName: 'mobil-gris-player-id',
	playerNameMinLength: 2,
	highscoreMaxPlayers: 10,
	inactivityLimitSeconds: 30,
	timeBetweenTasks: 1600,
	gameMinModules: 2,
	gameFacts: 4,
	moduleAvrTimePlayLimit: 5,
	screenBaseWidth: 414,
	volume: 0.8,
	gamesDbName,
	playersDbName,
	modulesDbName
};

export default appConfig;