import {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import Background from 'components/background/background';
import Highscore from 'components/player/game/highscore/highscore';
import './highscore-page.scss';

const HighscorePage = () => {
	/* Game id (from router) */
	const {gameId} = useParams();

	/* Game subscription and data*/
	const unsubscribeGame = useRef(null);
	const [gameData, setGameData] = useState(null);

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Subscribe to game data */
		subscribeToGame();
			
		/* Component will unmount */
		return () => {
			/* Cancel game subscription */
			if (unsubscribeGame.current) unsubscribeGame.current();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	
	/**
	 * Subscribe to game
	 * @returns {promise}
	 */
	const subscribeToGame = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const gameIdLowercase = gameId.toLowerCase();
			unsubscribeGame.current = db.collection(appConfig.gamesDbName).doc(gameIdLowercase).onSnapshot((doc) => {
				if (doc.exists) {
					/* Game exists, update game data state */
					const gameData = {id: doc.id, ...doc.data()};
					setGameData(gameData);
					resolve({ status: 'success', gameData });
				} else {
					/* Game does not exist */
					resolve({status: 'error', error: 'no-game'});
				}
			});
		});
	};

	/* Highscore */
	return (
		<div className="HighscorePage">
			<Background offsetMultiplier={0} isSoundOn={false} />
			{gameData && <Highscore gameData={gameData} />}
		</div>
	);
};

export default HighscorePage;