/* Game engines */
const multipleChoicePoints = {
	basePoints: 5,
	minusPointsPerWrongAnswers: 1,
	minimumPoints: 1
};

const matchPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
};

const sortPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
};

const orderPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
};

const spotErrorsMinusPoints = {
	minPoints: 1,
	errorLimits: [5, 3, 1],
	pointValues: [3, 2, 1]
};

export {
	sortPoints,
	orderPoints,
	matchPoints,
	spotErrorsMinusPoints,
	multipleChoicePoints
};