const cookieUiTexts = {
	text: `Vi vil gerne gemme dine valgte handlinger og din progression i spillet i en cookie.
		<br /><br />Du kan ikke spille uden denne cookie. Tryk "JA" for at acceptere den.`,
	yes: 'Ja',
	no: 'Nej'
};

const loginUiTexts = {
	login: 'Log ind',
	logout: 'Log ud',
	backToLogin: 'Tilbage til login',
	passwordIsReset: 'Du har fået tilsendt en e-mail med et link til at nulstille dit password.',
	facilitatorResetPw: 'Nulstil password',
	resetPassword: 'Nulstil password',
	email: 'E-mail',
	password: 'Password',
	reset: 'Nulstil',
	/* Login (player) */
	startText: 'Skriv dit navn eller indtast navnet på dit vinderteam i dag og kom i gang med quizzen',
	noGameText: `Hov, der er ikke noget spil her!<br /> Det ser ud til at din spilkode er forkert?`,
	noGameIdText: `Hov, der er ikke noget spil her!<br /> Har du husket at tilføje en spilkode til din URL?`,
	choose: 'Vælg',
};

const errorUiTexts = {
	/* General error */
	unknownError: 'Fejl: Ukendt fejl.',
	/* Login (facilitator) */
	emptyFields: 'Du skal udfylde alle felter.',
	/* Login / reset password (facilitator) */
	emailNotFound: 'Der findes ingen bruger med denne email.',
	invalidEmail: 'Din email er ikke gyldig.',
	invalidEmailPasswordFormat: 'Fejl. Check om din email og password er korrekte.',
	/* Login (player) */
	emptyNameField: 'Du skal udfylde dit navn.',
	tooShortName: 'Dit navn skal være på mindst 2 tegn.',
	duplicateName: 'Bruger med dette navn eksisterer allerede!',
	noUserData: 'Din bruger eksiterer ikke. Log ud og opret en ny.',
	tooFewFacts: 'Du skal vælge %number% "Vidste du at ...".',
	maxFacts: 'Du kan ikke vælge flere "Vidste du at ..."',
	tooFewModules: 'Du skal vælge mindst %number% moduler.',
	taskDataNotFound: 'Error: Could not find task data',
	taskTypeNotFound: 'Error: Could not find task type'
};

const facilitatorUiTexts = {
	activeGames: 'Aktive spil',
	newGame: 'Nyt spil',
	nameOfGameLabel: 'Navn på spil',
	nameOfGamePlaceholder: 'Spil 7E',
	descriptionOfGameLabel: 'Beskrivelse',
	selectedModules: 'Valgte moduler',
	subjects: 'Emner',
	continue: 'Fortsæt',
	createGame: 'Opret spil',
	cancel: 'Afbryd',
	numberOfPlaythroughs: 'Antal gennemspilninger',
	didYouKnow: 'Vidste du at',
	didYouKnowInfo: `Vælg hvilke %numberOfFacts% "Vidste du at..."  du vil have med i spillet. Det er bedst hvis de passer sammen med spørgsmålsmodulerne.`,
	choose: 'Vælg',
	selected: 'Valgte',
	modules: 'Moduler',
	modulesInfo: `Vælg hvilke spørgsmålsmoduler der skal med i spillet. Tryk på et emne for at se hvilke moduler der kan vælges fra det. Tryk på et modul for at vælge det eller se hvilke spørgsmål det indeholder. Vi anbefaler 3 moduler i de fleste tilfælde.`,
	forAllPlays: 'For samtlige gennemspilninger',
	player: 'Spiller',
	point: 'Point',
	playTime: 'Tid',
	stats: 'Statistik',
	forGameActivity: 'For spilaktivitet',
	startedGames: 'Startede spil',
	completedGames: 'Gennemførte spil',
	avrCompletionTime: 'Gns. tid til gennemført',
	qrCode: 'QR-kode',
	qrDownloadInfo: 'Download QR-kode for at dele spillet.',
	downloadSvg: 'Download (svg)',
	downloadPng: 'Download (png)',
	back: 'Tilbage',
	deleteGame: 'Slet spil',
	deleteGamePopup: 'Er du sikker på du vil slette spillet?',
	yesDelete: 'Ja, slet',
	noCancel: 'Nej, afbryd',
	tasks: 'Opgaver',
	open: 'Se spørgsmål',
	toggleHighscore: 'Vælg eller fravælg highscore liste sidst i spillet.',
	finish: 'Afslut',
	average: 'Gns:'
};

const gameUiTexts = {
	scoreboard: 'Scoreboard',
	task: 'opgave',
	ok: 'ok',
	orderPopupTitle: `Du fik %correctAnswers%/%totalAnswers%.`,
	orderPopupText1: `Prøv igen. Denne gang viser vi dig hvad der er korrekt.`,
	orderPopupText2: `Prøv igen!`,
	task: 'opgave',
	trueFalseInfo: `Angiv om udsagnene er sande eller falske ved at trykke på et udsagn og derefter på sandt eller falsk.`,
	matchInfo: `Par begreberne på den ene side med begreberne på den anden.`,
	gameOver1: `Godt klaret!<br /><br />Du fik %totalPoints% point ud af %maxPoints% mulige point.`,
	gameOver2: `Ok gået!<br /><br />Du fik %totalPoints% point ud af %maxPoints% mulige point.`,
	gameOver3: `Tjaa!<br /><br />Du fik %totalPoints% point ud af %maxPoints% mulige point. Prøv igen og se om du kan gøre det bedre.`,
}

export default {
	cookieUi: cookieUiTexts,
	gameUiTexts,
	loginUiTexts,
	errorUiTexts,
	facilitatorUiTexts,
}