import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import { getText, renderMarkdown } from 'helpers/text-helper';
import { getModuleAverageTime } from 'helpers/module-helper';
import './module-option.scss';

const ModuleOption = ({moduleData, toggleModule, modules}) => {
	const [isSelected, setIsSelected] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const averageTime = getModuleAverageTime(modules, moduleData.id);
	let stringTime = 'XX:XX';
	if (averageTime) {
		const seconds = Math.floor(averageTime / 1000);
		const minutes = Math.floor(seconds / 60);
		const shownSeconds = seconds % 60;
		stringTime = String(minutes).padStart(2, '0') + ':' + String(shownSeconds).padStart(2, '0');
	}

	return (
		<div className='ModuleOption'>
			<div className='ModuleOption-optionWrapper'>
				<div className={'ModuleOption-header ' + (isSelected ? 'selected' : '')} 
					onClick={() => {setIsSelected(!isSelected);}}>
					<div className='ModuleOption-title'>
						<span>{moduleData.title}</span>
					</div>
					<div className='ModuleOption-averageTime'>
						<span>{getText('facilitatorUiTexts', 'average') + ' ' + stringTime}</span>
					</div>
				</div>
				{isSelected &&
					<div className='ModuleOption-dropdown'>
						<div className='ModuleOption-button'>
							<Button
								text={getText('facilitatorUiTexts', 'choose')}
								classes={['facilitator', 'small', 'responsive']}
								onClick={() => {toggleModule(moduleData.id);}}
							/>
						</div>
						<div className='ModuleOption-button'>
							<Button
								text={getText('facilitatorUiTexts', 'open')}
								classes={['facilitator', 'small', 'responsive']}
								onClick={() => {setIsPopupOpen(true);}}
							/>
						</div>
					</div>
				}
			</div>
			{isPopupOpen &&
				<div className='ModuleOption-modulePopup' onClick={() => {setIsPopupOpen(false);}}>
					<div className='ModuleOption-popupDialogue'
						onClick={(e) => {e.stopPropagation();}}
					>
						<div className='ModuleOption-popupTitle'>
							<span>{getText('facilitatorUiTexts', 'tasks')}</span>
						</div>

						<div className='ModuleOption-taskList'>
							{moduleData.tasks && moduleData.tasks.map((task, index) => {
								return (
									<div key={index} className='ModuleOption-task'>
										{renderMarkdown(task.text)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			}
		</div>
	);
};

ModuleOption.propTypes = {
	moduleData: PropTypes.object.isRequired,
	toggleModule: PropTypes.func.isRequired,
	modules: PropTypes.array.isRequired
};

export default ModuleOption;