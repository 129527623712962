import { useEffect, useState } from 'react';
import appConfig from 'config/app.config';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './fact.scss';

const Fact = ({taskData, handleGoToNextTask}) => {
	const [nextButtonDelay, setNextButtonDelay] = useState(null);

	// Animation length values
	const farmerInLength = 1; // seconds
	const farmerOutLength = 0.25; // seconds
	const textBoxFadeLength = 0.25; // seconds
	const screenWidth = window.screen.width;
	
	/**
	 * Component will unmount
	 */
	useEffect(() => {
		return () => {
			if (nextButtonDelay) {
				clearTimeout(nextButtonDelay);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleNextButton = () => {
		const delay = setTimeout(function() {
			handleGoToNextTask();
		}, 1000);
		setNextButtonDelay(delay);
	};

	return (
		<div className={'Fact ' + (taskData.background ? taskData.background : '')}>
			<div className={'Fact-farmer ' + (nextButtonDelay ? 'leaving' : '')}
				style={{
					animationDuration: nextButtonDelay ? 
						(screenWidth / appConfig.screenBaseWidth * farmerOutLength) + 's'
						: 
						(screenWidth / appConfig.screenBaseWidth * farmerInLength) + 's',
				}}
			/>
			{(taskData.title || taskData.text) && 
				<div className={'Fact-content ' + (nextButtonDelay ? 'fade' : '')}
					style={{
						animationDuration: (screenWidth / appConfig.screenBaseWidth * textBoxFadeLength) + 's',
						animationDelay: nextButtonDelay ?
							'0s'
							:
							(screenWidth / appConfig.screenBaseWidth * farmerInLength) + 's',
					}}
				>
					{taskData.title && 
						<div className="Fact-title">
							<span>{taskData.title}</span>
						</div>
					}
					{taskData.text && 
						<div className="Fact-text">
							{renderMarkdown(taskData.text)}
						</div>
					}
				</div>
			}
			<div className="Fact-nextBtn">
				<Button 
					classes={['next']}
					onClick={() => {handleNextButton();}}
				/>
			</div>
		</div>
	);
};

Fact.propTypes = {
	taskData: PropTypes.object.isRequired,
	handleGoToNextTask: PropTypes.func.isRequired,
};

export default Fact;
