import {getText} from './text-helper';
import appConfig from 'config/app.config';
import Fact from 'components/player/game/task/engines/fact/fact';
import HighFive from 'components/player/game/task/engines/high-five/high-five';
import MultipleChoice from 'components/player/game/task/engines/multiple-choice/multiple-choice';
import Order from 'components/player/game/task/engines/order/order';
import Sort from 'components/player/game/task/engines/sort/sort';
import SpotErrors from 'components/player/game/task/engines/spot-errors/spot-errors';
import Match from 'components/player/game/task/engines/match/match';
import GameOver from 'components/player/game/task/engines/game-over/game-over';

/**
 * Gets module type based on string id. Prints error if type does not exist.
 * @param {string} type 
 * @returns module object, or null if type does not exist
 */
export function getTaskComponentFromType(type) {
	let taskComponent = Fact;
	switch (type) {
	case 'sort':
		taskComponent = Sort;
		break;
	case 'spot-errors':
		taskComponent = SpotErrors;
		break;
	case 'multiple-choice':
		taskComponent = MultipleChoice;
		break;
	case 'order':
		taskComponent = Order;
		break;
	case 'match':
		taskComponent = Match;
		break;
	case 'fact':
		taskComponent = Fact;
		break;
	case 'high-five':
		taskComponent = HighFive;
		break;
	case 'gameover':
		taskComponent = GameOver;
		break;
	default:
		console.error(getText('errorUiTexts', 'taskTypeNotFound') + ': ' + type);
		break;
	}

	return taskComponent;
};

/**
 * Get number of miliseconds since last activity timestamp
 * @param {number} timestamp 
 * @returns 
 */
export function getTimeSinceLastActivity(timestamp) {
	/* Get difference in miliseconds */
	const seconds = Date.now() - timestamp;

	/* Return differnce (max value is inactivity limit) */
	return Math.min(seconds, appConfig.inactivityLimitSeconds * 1000);
};

/**
 * Get combined time in milliseconds for all player tasks.
 * @param {array} tasks 
 * @returns 
 */
export function getCombinedTaskTime(tasks) {
	let milliseconds = 0;

	tasks.forEach((task) => {
		milliseconds += task.time;
	});

	return milliseconds;
};