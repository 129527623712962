import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {setCookie} from 'helpers/cookie-helper';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './player-login.scss';

const PlayerLogin = ({gameId, deviceInfo, setPlayerId}) => {
	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);

	/* Player name (input) */
	const [playerName, setPlayerName] = useState(null);
	
	/* Feedback */
	const [feedback, setFeedback] = useState(null);

	/**
	 * Update player name input
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
		setPlayerName(event.target.value);
	};

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	const handleLogin = (event) => {
		/* Prevet default input action */
		if (event) event.preventDefault();
		
		/* Update loading status */
		setIsLoading(true);

		/* Check if valid name */
		let newFeedback = null;
		if (!playerName || playerName.length === 0) {
			newFeedback = getText('errorUiTexts', 'emptyNameField');
		} else if (playerName.length < appConfig.playerNameMinLength) {
			newFeedback = getText('errorUiTexts', 'tooShortName');
		}

		const db = firebase.firestore();
		db.collection(appConfig.playersDbName).where('playerName', '==', playerName).get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (doc && doc.data()) {
					newFeedback = getText('errorUiTexts', 'duplicateName');
				}
			});

			if (!newFeedback) {
				/* Valid name, create player and log in */
				db.collection(appConfig.playersDbName).add({
					playerName: playerName,
					gameId: gameId,
					created: dayjs(new Date()).format('YYYY-MM-DD')
				}).then((docRef) => {
					/* Success: set player id in cookie and state */
					setCookie(appConfig.playerIdCookieName, docRef.id);
					setPlayerId(docRef.id);
				}).catch((error) => {
					/* Error: show feedback */
					console.error(error);
					setIsLoading(false);	
					setFeedback(getText('errorUiTexts', 'unknownError'));
				});
			} else {
				/* Error: name not valid, show feedback */
				setIsLoading(false);
				setFeedback(newFeedback);
			}
		});
	};

	return (
		<div className={'PlayerLogin ' + deviceInfo.orientation}>
			<form className='PlayerLogin-form'>
				<div className='PlayerLogin-loginMessage'>
					<span>{getText('loginUiTexts', 'startText')}:</span>
				</div>
				<div className='PlayerLogin-inputWrapper'>
					<input
						className='PlayerLogin-input'
						name="name"
						type="text"
						placeholder={''}
						autoComplete="section-PlayerLogin name"
						value={playerName ? playerName : ''}
						onChange={(event) => {handleInput(event);}}
					/>
				</div>
				<div className='PlayerLogin-loginButton'>
					<Button 
						type={'submit'}
						isLoading={isLoading}
						classes={['blue']}
						text={getText('loginUiTexts', 'choose')} 
						onClick={handleLogin}
					/>
				</div>
				<div className="PlayerLogin-feedback">
					<span>{feedback}</span>
				</div>
			</form>
		</div>
	);
};

PlayerLogin.propTypes = {
	gameId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	setPlayerId: PropTypes.func.isRequired,
};

export default PlayerLogin;