/**
 * Get random number between min and max
 * @param {number} min 
 * @param {number} max 
 * @returns 
 */
export function getRandomNumber(min, max) {
	return Math.random() * (max - min) + min;
}

/**
 * Get random whole number between min and max.
 * @param {number} min 
 * @param {number} max 
 * @returns 
 */
export function getRandomWholeNumber(min, max) {
	return Math.floor(Math.random() * (max - min) + min);
}