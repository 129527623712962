import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './sort-dnd-preview.scss';

const SortDndPreview = ({layout, subtype, itemsData}) => {
	const {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemData = itemsData.find((i) => {return i.id === item.id;});

	if (!itemData) return null;


	return (
		<div 
			className={'SortDndDragLayer'} 
			style={style}
		>
			<div className={'SortDndDragLayer-item item-' + itemData.id + (layout ? ' ' + layout : '')}>
				{subtype !== 'images' && <span>
					{itemData.text ? itemData.text : null}
				</span>}
			</div>
		</div>
	);
};

SortDndPreview.propTypes = {
	layout: PropTypes.string.isRequired,
	subtype: PropTypes.string,
	itemsData: PropTypes.array.isRequired,
	
};

export default SortDndPreview;