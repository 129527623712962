import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import './task-intro.scss';

const TaskIntro = ({
	showNumberOfAnswersToSelect, 
	showMatchTrueFalseInfo, 
	showMatchInfo, 
	numberOfCorrectAnswers, 
	text, 
	image
}) => {
	/* Get required number of answers */
	const showRequiredNumberOfAnswers = (
		showNumberOfAnswersToSelect && 
		numberOfCorrectAnswers && 
		(numberOfCorrectAnswers > 0) ? true : false
	);

	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className='TaskIntro-text'>
				{text && renderMarkdown(text)}
				{showRequiredNumberOfAnswers &&
					<span>{getText('facilitatorUiTexts', 'choose')} {numberOfCorrectAnswers}:
					</span>
				}
				{showMatchTrueFalseInfo &&
					<span>{getText('gameUiTexts', 'trueFalseInfo')}</span>
				}
				{showMatchInfo &&
					<span>{getText('gameUiTexts', 'matchInfo')}</span>
				}
			</div>
			{image && <div className='TaskIntro-image' />}
		</div>
	);
};

TaskIntro.propTypes = {
	showNumberOfAnswersToSelect: PropTypes.bool,
	numberOfCorrectAnswers: PropTypes.number,
	text: PropTypes.string,
	image: PropTypes.string,
	showMatchTrueFalseInfo: PropTypes.bool,
	showMatchInfo: PropTypes.bool,
};

export default TaskIntro;
