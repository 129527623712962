import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import FacilitatorGames from './games/facilitator-games';
import FacilitatorGame from './game/facilitator-game';
import './facilitator.scss';

const Facilitator = ({facilitatorData, handleLogout}) => {
	/* Subscriptions */
	const unsubscribeGames = useRef(null);
	const unsubscribePlayers = useRef(null);
	const unsubscribeModules = useRef(null);

	/* Games */
	const [games, setGames] = useState([]);

	/* Players */ 
	const [players, setPlayers] = useState([]);

	/* Modules */
	const [modules, setModules] = useState([]);

	/* Selected game id */
	const [selectedGameId, setSelectedGameId] = useState(null);

	/* Show "new game"-popup */
	const [showNewGamePopup, setShowNewGamePopup] = useState(false);

	/**
	 * Component did mount / component will unmount
	 */
	useEffect(() => {
		/* Component did mount */

		/* Subscribe to all games */
		subscribeToGames();

		/* Subscribe to all players */
		subscribeToPlayers();

		/* Subscribe to modules (stats) */
		subscribeToModules();

		/* Component will unmount */
		return () => {
			/* Cancel subscriptions */
			if (unsubscribeGames.current) unsubscribeGames.current();
			if (unsubscribePlayers.current) unsubscribePlayers.current();
			if (unsubscribeModules.current) unsubscribeModules.current();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Subscribe to all games
	 * Facilitators share all games
	 * @returns {promise}
	 */
	const subscribeToGames = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			unsubscribeGames.current =  
				db.collection(appConfig.gamesDbName).onSnapshot((querySnapshot) => {
					let games = [];
					if (!querySnapshot.empty) {
						games = querySnapshot.docs.map((doc) => {
							return {id: doc.id, ...doc.data()};
						});
					}
					setGames(games);
					resolve({status: 'success'});
				},
				(error) => {
					console.error(error);
					resolve({status: 'error', error: error});
				});
		});
	};

	/**
	 * Subscribe to all players
	 * @returns {promise}
	 */
	const subscribeToPlayers = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			unsubscribePlayers.current =  
				db.collection(appConfig.playersDbName).onSnapshot((querySnapshot) => {
					let players = [];
					if (!querySnapshot.empty) {
						players = querySnapshot.docs.map((doc) => {
							return {id: doc.id, ...doc.data()};
						});
					}
					setPlayers(players);
					resolve({status: 'success'});
				},
				(error) => {
					console.error(error);
					resolve({status: 'error', error: error});
				});
		});
	};

	/**
	 * Subscribe to modules (stats)
	 * @returns {promise}
	 */
	const subscribeToModules = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			unsubscribeModules.current =  
				db.collection(appConfig.modulesDbName).onSnapshot((querySnapshot) => {
					let modules = [];
					if (!querySnapshot.empty) {
						modules = querySnapshot.docs.map((doc) => {
							return {id: doc.id, ...doc.data()};
						});
					}
					setModules(modules);
					resolve({status: 'success'});
				},
				(error) => {
					console.error(error);
					resolve({status: 'error', error: error});
				});
		});
	};

	/**
	 * Create a new game with given title and description, 
	 * auto-generate unique login code, also functions as the game unique id
	 * @param {string} title 
	 * @param {string} description 
	 * @param {array} selectedModuleIds
	 * @returns 
	 */
	const createNewGame = (title, description, selectedFactIds, selectedModuleIds, hasHighscore) => {
		return new Promise((resolve) => {
			apiHelper('facilitator/create-new-game', {
				facilitatorId: facilitatorData.id, 
				title, 
				description, 
				selectedFactIds, 
				selectedModuleIds,
				hasHighscore
			}).then((response) => {
				resolve(response);
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Delete a game and all its players
	 * @returns {promise}
	 */
	const deleteGame = () => {
		return new Promise((resolve) => {
			apiHelper('facilitator/delete-game', {
				gameId: selectedGameId
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/* Selected game data (if any) */
	const selectedGameData = (selectedGameId
		? games.find((g) => {return g.id === selectedGameId;})
		: null
	);

	/* Players of selected game (if any) */
	const selectedGamePlayers = (selectedGameId
		? players.filter((p) => {return p.gameId === selectedGameId;})
		: []
	);


	/* Page title & component */
	let pageTitle = getText('facilitatorUiTexts', 'activeGames');
	let pageSubTitle = null;
	let PageComponent = FacilitatorGames;
	if (selectedGameData) {
		pageTitle = selectedGameData.title;
		pageSubTitle = selectedGameData.description;
		PageComponent = FacilitatorGame;
	}
	
	return (
		<div className={'Facilitator' + (pageSubTitle ? ' subtitle' : '')}>
			{/* Content (all games or specific game) */}
			<div className="Facilitator-content">
				<PageComponent 
					showNewGamePopup={showNewGamePopup}
					games={games}
					players={players}
					modules={modules}
					selectedGameData={selectedGameData}
					selectedGamePlayers={selectedGamePlayers}
					createNewGame={createNewGame}
					setSelectedGameId={setSelectedGameId}
					setShowNewGamePopup={setShowNewGamePopup}
					deleteGame={deleteGame}
				/>
			</div>
			
			{/* Title */}
			{!showNewGamePopup && <div className="Facilitator-title">
				<span>{pageTitle}</span>
			</div>}

			{/* Subtitle */}
			{pageSubTitle && <div className="Facilitator-subTitle">
				<span>{pageSubTitle}</span>
			</div>}

			{/* Logout btn */}
			{!selectedGameId && <div className="Facilitator-logoutBtn">
				<Button 
					classes={['facilitator']}
					text={getText('loginUiTexts', 'logout')}
					onClick={() => {handleLogout();}}
				/>
			</div>}


		</div>
	);
};

Facilitator.propTypes = {
	facilitatorData: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Facilitator;