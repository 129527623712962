import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OrderDndItem from './order-dnd-item';
import OrderDndPreview from './order-dnd-preview';
import OrderDndGraphic from './order-dnd-graphic';
import './order-dnd-items.scss';

const OrderDndItems = ({
	handleSetPreview, 
	isCompleted,
	previewList,
	currentPreview,
	itemList,
	taskData,
	handleSwitchItems,
	handleUnsetPreview
}) => {
	const [currentDraggedItemId, setCurrentDraggedItemId] = useState(null);

	return (
		<div id="orderItems" className="OrderDndItems-items">
			{itemList.map((item, index) => {
				const itemData = taskData.items.find((i) => {return i.id === item.id;});
				if (!itemData) return null;
				let isDraggable = true;
				let classes = null;
				const isPlacedCorrect = itemData.correctOrderNumbers.indexOf(index + 1) >= 0;
				if (isCompleted || (item.isLocked && isPlacedCorrect)) {
					isDraggable = false;
					classes = ['correct'];
				}

				let previewItemData = null;
				if (previewList) {
					previewItemData = previewList.find((preview) => {
						return preview.item.id === item.id;
					});
				}

				let itemLocation = item.location;
				if (previewItemData) {
					itemLocation = previewItemData.location;
				}

				const isLast = index === itemList.length - 1;

				return (
					<div key={item.id} className={'OrderDndItems-item' + (isLast ? ' isLast' : '')}>
						<div className='OrderDndItems-itemNumber'>
							<span>{index + 1}.</span>
						</div>
						<div className={'OrderDndItems-itemContainer location-' + itemLocation}>
							<OrderDndItem
								isDraggable={isDraggable} 
								classes={classes}
								itemIndex={index}
								itemData={itemData}
								itemLocation={itemLocation}
								isLocked={item.isLocked}
								currentPreview={currentPreview}
								previewItemData={previewItemData}
								previewList={previewList}
								handleSetPreview={handleSetPreview}
								handleSwitchItems={handleSwitchItems}
								handleUnsetPreview={handleUnsetPreview}
								setCurrentDraggedItemId={setCurrentDraggedItemId}
							/>
						</div>
						<div className={'OrderDndItems-graphicContainer location-' + itemLocation}>
							<OrderDndGraphic 
								itemData={itemData}
								classes={classes}
								isDragging={currentDraggedItemId === itemData.id}
							/>
						</div>
					</div>
				);
			})}
			<OrderDndPreview orderedItems={itemList} itemsData={taskData.items} />
		</div>
	);
};

OrderDndItems.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	previewList: PropTypes.array,
	currentPreview: PropTypes.object,
	itemList: PropTypes.array.isRequired,
	taskData: PropTypes.object.isRequired,
	handleSwitchItems: PropTypes.func.isRequired,
	handleSetPreview: PropTypes.func.isRequired,
	handleUnsetPreview: PropTypes.func.isRequired,
};

export default OrderDndItems;