import {useState, useEffect, useRef} from 'react';
import firebase from 'firebase/compat/app';
import FacilitatorLogin from 'components/facilitator/login/facilitator-login';
import ResetPassword from 'components/facilitator/login/reset-password';
import Facilitator from './facilitator';
import './facilitator-route.scss';

const FacilitatorRoute = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [facilitatorData, setFacilitatorData] = useState(null);
	const [isResettingPassword, setIsResettingPassword] = useState(false);

	/**
	 * Toggles password reset
	 */
	const togglePasswordReset = () => {
		setIsResettingPassword(!isResettingPassword);
	};
	const unsubscribeOnAuthStateChanged = useRef(null);

	/**
	 * Checking if logged in on mount, and handling unsubscriptions on unmount
	 */
	useEffect(() => {
		checkIfLoggedIn();

		return () => {
			if (unsubscribeOnAuthStateChanged.current) {
				unsubscribeOnAuthStateChanged.current();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Check if user is logged in
	 */
	const checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (unsubscribeOnAuthStateChanged.current !== null) {
			unsubscribeOnAuthStateChanged.current();
		}

		// Subscribe to onAuthStateChanged
		unsubscribeOnAuthStateChanged.current = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				const isFacilitator = user.email !== null;
				if (isFacilitator) {
					/* Logged in as facilitator */
					setIsLoggedIn(true);
					setFacilitatorData({id: user.uid, email: user.email});
				} else {
					setIsLoggedIn(false);
					setFacilitatorData(null);
				}
			} else {
				setIsLoggedIn(false);
				setFacilitatorData(null);
			}
		});	
	};
	
	/**
	 * Logout
	 */
	const handleLogout = () => {
		firebase.auth().signOut();
		setIsLoggedIn(false);
		setFacilitatorData(null);
	};

	return (
		<div className='FacilitatorRoute'>
			{isLoggedIn ?
				<Facilitator facilitatorData={facilitatorData} handleLogout={handleLogout}/>
				:
				isResettingPassword ?
					<ResetPassword togglePasswordReset={togglePasswordReset}/>
					:
					<FacilitatorLogin togglePasswordReset={togglePasswordReset}/>
			}
		</div>
	);
};

export default FacilitatorRoute;