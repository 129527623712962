import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { matchPoints } from 'data/points-data';
import {shuffleArray} from 'helpers/array-helper';
import TaskIntro from '../task-intro/task-intro';
import './match.scss';

const Match = ({playerTaskData, taskData, handleCompleteTask, updateLoggedTime}) => {
	const [errorCount, setErrorCount] = useState(0);

	/* Check if completed already */
	const [isCompleted, setIsCompleted] = useState(false);
	/* Track available options */
	const [optionIds, setOptionIds] = useState([]);
	/* Track available answers */
	const [answerIds, setAnswerIds] = useState([]);
	/* Track Completed options */
	const [completedAnswers, setCompletedAnswers] = useState([]);
	/* Track Current cliked option */
	const [currentOption, setCurrentOption] = useState(null);
	/* Track Current cliked answer */
	const [currentAnswer, setCurrentAnswer] = useState(null);

	/* Animate selected options */
	const [optionIdToAnimateCorrect, setOptionToAnimateCorrect] = useState(null);
	const [animationDelayTimer, setAnimationDelayTimer] = useState(null);

	/* Wrong answer animation */
	const [optionIdToAnimateWrong, setOptionToAnimateWrong] = useState(null);
	/* Is game paused */
	const [isPaused, setIsPaused] = useState(false);

	/* Update selected items if new task */
	useEffect(() => {
		let isTaskCompleted = playerTaskData && playerTaskData.isCompleted;
		setIsCompleted(isTaskCompleted);
		setCompletedAnswers(getSelectedOptionIds());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id, playerTaskData]);

	/**
	 * Complete task
	 */
	const completeTask = (answers, points) => {
		/* Save completed task */
		handleCompleteTask(
			points,
			errorCount,
			answers
		);
	};
	/**
	 * onMount remove animation delay 
	 */
	useEffect(() => {
		return () => {
			clearTimeout(animationDelayTimer);
			setCompletedAnswers([]);
			setIsPaused(false);
		};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get option and answer ids and shuffle them
	useEffect(() => {
		let optionIds = [];
		let answerIds = [];
		if (!taskData.shuffleOptions) {
			optionIds = taskData.options.map((option) => {return option.id;});
			answerIds = taskData.answers.map((answer) => {return answer.id;});
		} else {
			if (taskData.layout !== 'sortType') {
				optionIds = shuffleArray(taskData.options.map((option) => {return option.id;}));
			} else {
				optionIds = taskData.options.map((option) => {return option.id;});
			}
			answerIds = shuffleArray(taskData.answers.map((answer) => {return answer.id;}));
		}
		
		/* update the new data and remove selected */
		setAnswerIds(answerIds);
		setOptionIds(optionIds);
		setCurrentAnswer(null);	
		setCurrentOption(null);
	}, [taskData]);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	 const getSelectedOptionIds = () => {
		let optionIds = [];

		if (playerTaskData && playerTaskData.taskData) optionIds = playerTaskData.taskData;
		return optionIds;
	};
	
	/**
	 * Check when an new option or answer if they match
	 */
	useEffect(() => {
		checkMatch();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentAnswer, currentOption]); 

	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	 const selectOptionId = (optionId) => {
		/* Already selected */
		if (currentOption === optionId) return;

		/* Update logged time */
		updateLoggedTime();

		/* update current option */
		setCurrentOption(optionId);
	};

	/**
	 * Select answer
	 * @param {number} answerId 
	 * @returns 
	 */
	const selectAnswerId = (answerId) => {
		/* Already selected */
		if (currentAnswer === answerId) return;

		/* Update logged time */
		updateLoggedTime();
		
		/* update current option */
		setCurrentAnswer(answerId);
	};

	/**
	 * Select answer
	 * @param {number}  
	 * @returns 
	 */
	 const checkMatch = () => {
		/* Nothing to match to */
		if (!currentOption || !currentAnswer) return;

		/* get the current option using the id */
		const currentOptionData = taskData.options.find((option) => {return option.id === currentOption;});

		/* get the current answer using the id */
		const currentAnswerData = taskData.answers.find((answer) => {return answer.id === currentAnswer;});

		/* Check if option match to answer */
		const isMatching = currentOptionData.answers.includes(currentAnswerData.id);

		const currentSelection = {id: currentAnswer, isCorrect: isMatching};
		setIsPaused(true);
		if (isMatching) {
			/* if it has a match update completed answers state */
			setCompletedAnswers([...completedAnswers, currentSelection]);
			setOptionToAnimateCorrect(currentOption);

			/** on match deselect answer and option */
			const delay = setTimeout(function() {
				setOptionToAnimateCorrect(null);
				setCurrentAnswer(null);
				setCurrentOption(null);
				setIsPaused(false);
			}, 800);

			setAnimationDelayTimer(delay);
		} else {
			/** only deselect answer when not matching */
			setOptionToAnimateWrong(currentOption);
			setErrorCount(errorCount + 1);

			// We wait for animations to finish before we complete task
			const delay = setTimeout(function() {
				setCurrentAnswer(null);
				setCurrentOption(null);
				setOptionToAnimateWrong(null);
				setIsPaused(false);
			}, 800);

			setAnimationDelayTimer(delay);
		}
		/** check if task is complete if not handleAnswer */
		if (isTaskCompleted([...completedAnswers, currentSelection])) {
			/* Complete task */
			let points = matchPoints.minPoints;
			let pointIndex = matchPoints.pointLimits.findIndex((limit) => {return errorCount <= limit;});

			if (pointIndex !== -1) {
				points = matchPoints.pointValues[pointIndex];
			}
			completeTask([...completedAnswers, currentSelection], points);
		}
	};
	
	/** 
	 * Check if the option has all answers complete
	*/
	const isOptionCompleted = (option, allAnswers) => {
		return option.answers.every((answer) => {
			return allAnswers.find((a)=>{
				if (a.id === answer && a.isCorrect) {
					return true;
				}
				return false;
			});
		});
	};

	/**
	 * Gets optionclass for option
	 * @param {object} optionData 
	 * @param {bool} isAnswered 
	 * @param {bool} hasAnswers 
	 * @returns 
	 */
	const getOptionClass = (optionData, isAnswered, hasAnswers) => {
		const isSelected = (currentOption === optionData.id);

		let optionClass = 'Match-option';
		if (isSelected) {
			optionClass += ' selected';
		} else if (!isSelected && !isCompleted && (!isAnswered || !hasAnswers)) {
			optionClass += ' notSelected';
		} else if (!isSelected && isCompleted) {
			optionClass += ' completed';
		}
		if (isAnswered && hasAnswers) optionClass += ' answered';
		if (isSelected && !isCompleted) {
			if ((isAnswered && hasAnswers) || (hasAnswers && optionIdToAnimateCorrect === optionData.id)) {
				optionClass += ' correct';
			} else if (currentAnswer) {
				optionClass += ' wrong';
			}
		}
		optionClass += ' ' + taskData.layout + ' option-' + optionData.id;

		return optionClass;
	};

	/**
	 * Gets optionclass for option with subtype blink
	 * @param {object} optionData
	 * @returns 
	 */
	const getOptionClassForSubtype = (optionData) => {
		const isSelected = (currentOption === optionData.id);

		let optionClass = 'Match-option';

		if (optionIdToAnimateCorrect === optionData.id) {
			optionClass += ' animateCorrect';
		} else if (optionIdToAnimateWrong === optionData.id) {
			optionClass += ' animateWrong';
		}
		if (isSelected) {
			optionClass += ' selected';
		}
		optionClass += ' ' + taskData.layout + ' option-' + optionData.id;

		return optionClass;
	};

	/**
	 * Check if the task is completed
	 */
	const isTaskCompleted = (allAnswers) => {
		return taskData.options.every((option) => {
			return isOptionCompleted(option, allAnswers);
		});
	};

	return (
		<div className={'Match ' + (taskData.layout ? ' ' + taskData.layout : '')}>
			<div id="taskIntro" className="Match-intro">
				<TaskIntro
					taskId={taskData.id}
					text={taskData.text}
					image={taskData.image}
					file={taskData.linkFile}
					showMatchTrueFalseInfo={(taskData.layout === 'sortType')}
					showMatchInfo={(taskData.layout !== 'sortType')}
				/>
			</div>
			<div className='Match-contentWrapper'>
				<div className="Match-optionsWrap">
					{optionIds.map((optionId) => {
						const optionData = taskData.options.find((option) => {return option.id === optionId;});
						if (!optionData) return null;
						
						/* if all the options answers is completed */
						const isAnswered = isOptionCompleted(optionData, completedAnswers);
						
						const hasAnswers = optionData.answers.length > 0;

						let optionClass = null;
						let isBlink = taskData.subtype && taskData.subtype === 'blink';
						
						isBlink ?
							optionClass = getOptionClassForSubtype(optionData)
							:
							optionClass = getOptionClass(optionData, isAnswered, hasAnswers);
						
						return (
							<div 
								key={optionData.id}
								className={optionClass}
								onClick={() => {
									if (isPaused) return;
									if (isCompleted) return;
									if (optionData.id === currentOption) {
										setCurrentOption(null);
										return;
									}
									selectOptionId(optionData.id);
								}}
							>
								<span>{optionData.option}</span>
							</div>
						);
					})}
				</div>
				<div className='Match-separator'/>
				<div className="Match-answersWrap">
					{answerIds.map((answerId, index) => {
						const answerData = taskData.answers.find((answer) => {return answer.id === answerId;});
						if (!answerData) return null;
						const isSelected = (currentAnswer === answerData.id);

						const isAnswered = completedAnswers.find((answer)=>{
							if (answer.id === answerData.id && answer.isCorrect) {
								return true;
							}
							return false;
						});

						let answerClass = 'Match-answer ' + taskData.layout;
						if (isSelected) {
							answerClass += ' selected';
						} else if (!isSelected && !isCompleted) {
							answerClass += ' notSelected';
						} else if (!isSelected && isCompleted) {
							answerClass += ' completed';
						}

						if (isAnswered) answerClass += ' answered';
				
						if (isSelected && !isCompleted) {
							if (isAnswered) {
								answerClass += ' correct';
							} else if (currentOption) {
								answerClass += ' animateWrong';
							}
						}

						answerClass += ' answer-' + answerId + ' position-' + index;
						if (!answerData.isText) answerClass += ' image';
						return (
							<div 
								key={answerId}
								className={answerClass} 
								onClick={() => {
									if (isAnswered) return;
									if (isPaused) return;
									if (answerData.id === currentAnswer) {
										setCurrentAnswer(null);
										return;
									}
									selectAnswerId(answerData.id);
								}}
							>
								{answerData.isText &&
									<span>{answerData.text}</span>
								}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

Match.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired
};

export default Match;
