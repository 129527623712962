import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import appConfig from 'config/app.config';
import { getGameTasks, getPlayerTasksData, getPlayerTaskData } from 'helpers/game-helper';
import { getTaskComponentFromType, getTimeSinceLastActivity } from 'helpers/task-helper';
import {getPlayTimesForModulesInGame} from 'helpers/module-helper';
import { getText } from 'helpers/text-helper';
import TaskNav from 'components/ui/task-nav/task-nav';
import './task.scss';

const Task = (props) => {
	/* Props */
	const {
		playerData, 
		gameData, 
		scrollToTop, 
		updatePlayerData, 
		handleBackgroundOffset, 
		setPage, 
		setStartGame,
		updateModulePlayTimes
	} = props;

	/* All unplayed game tasks (including facts) */
	const [gameTasksData, setGameTasksData] = useState([]);

	const [nextTaskDelay, setNextTaskDelay] = useState(null);

	/* Current task id */
	const [taskId, setTaskId] = useState(null);

	// Tracking time
	const [loggedTime, setLoggedTime] = useState(0);
	const [timeStamp, setTimeStamp] = useState(Date.now());

	/* Get current task data */
	const taskData = (taskId 
		? gameTasksData.find((t) => {return t.id === taskId;})
		: null
	);

	/* Get player data for current task */
	const playerTaskData = (taskId 
		? getPlayerTaskData(taskId, playerData)
		: null
	);

	/* Get current task component (depends on task type) */
	const TaskComponent = (taskData 
		? getTaskComponentFromType(taskData.type)
		: null
	);

	/**
	 * Component did mount / component will unmount
	 */
	useEffect(() => {
		const unplayedGameTasksAndFacts = getGameTasks(gameData, playerData);
		const currentTaskId = unplayedGameTasksAndFacts[0].id;
		setGameTasksData(unplayedGameTasksAndFacts);
		setTaskId(currentTaskId);
	
		/* Component will unmount */
		return () => {
			/* Update logged time */
			updateLoggedTime();

			if (nextTaskDelay) {
				clearTimeout(nextTaskDelay);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	/**
	 * Update logged time
	 * @param {bool} resetLoggedTime
	 */
	const updateLoggedTime = (resetLoggedTime = false) => {
		/* Get number of miliseconds since last update */
		const milliseconds = getTimeSinceLastActivity(timeStamp);
		const newLoggedTime = loggedTime + milliseconds;

		setLoggedTime(resetLoggedTime ? 0 : newLoggedTime);
		setTimeStamp(Date.now());
		return newLoggedTime;
	};

	/**
	 * Complete task
	 * @param {number} points 
	 * @param {number} errors 
	 * @param {object} taskData 
	 */
	const handleCompleteTask = (points, errors, taskData) => {	
		/* Get player tasks data */
		const playerTasksData = getPlayerTasksData(playerData);

		/* Get logged time, reset */
		const loggedTime = updateLoggedTime(true);

		/* Add data for completed task */
		playerTasksData.push({
			taskId: taskId,
			points: points,
			errors: errors,
			time: loggedTime,
			taskData: taskData,
			isCompleted: true,
		});

		/* Update total player points */
		const newTotalPoints = (playerData.totalPoints ? playerData.totalPoints + points : points);

		/* Check if last task, if so flag all tasks as completed */
		const taskIndex = gameTasksData.findIndex((t) => {return t.id === taskId;});
		const allTasksAreCompleted = (taskIndex + 1 >= gameTasksData.length - 1); // Not counting game over screen

		/* Update player data */
		updatePlayerData({tasks: playerTasksData, totalPoints: newTotalPoints, allTasksAreCompleted}).then(() => {
			/* Auto-continue to next task */
			const delay = setTimeout(function() {
				handleGoToNextTask();
			}, appConfig.timeBetweenTasks);
			setNextTaskDelay(delay);
		});
	};

	/**
	 * Go to next task, if no next task finish game and go to highscore page
	 */
	const handleGoToNextTask = () => {
		const taskIndex = gameTasksData.findIndex((t) => {return t.id === taskId;});
		if (taskIndex + 1 < gameTasksData.length) {
			/* Go to next task */
			const nextTaskId = gameTasksData[taskIndex + 1].id;
			if (gameTasksData[taskIndex + 1].type === 'fact' || gameTasksData[taskIndex + 1].type === 'high-five') {
				handleBackgroundOffset();
			}
			handleGoToTask(nextTaskId);
		} else {
			/* All tasks completed, go to highscore */
			setPage('highscore');
			
			/* Add module play times */
			updateModulePlayTimes(getPlayTimesForModulesInGame(gameData, playerData));
		}
	};
	
	/**
	 * Go to specific task id
	 * @param {string} newTaskId 
	 */
	const handleGoToTask = (newTaskId) => {
		setTaskId(newTaskId);
		scrollToTop();
	};
	
	/* Error: task data not defined */
	if (!taskData) {
		return (
			<div>{getText('errorUiTexts', 'taskDataNotFound')}</div>
		);
	}

	return (
		<div className={'Task ' + (taskData ? taskData.type : '')}>
			{/* Task component - depends on engine */}
			<DndProvider options={HTML5toTouch}>
				<TaskComponent 
					taskData={taskData}
					gameData={gameData}
					playerData={playerData}
					playerTaskData={playerTaskData}
					handleCompleteTask={handleCompleteTask}
					handleGoToNextTask={handleGoToNextTask}
					updateLoggedTime = {updateLoggedTime}
					setStartGame = {setStartGame}
				/>
			</DndProvider>

			{/* Developer task navigation */}
			{appConfig.showDevTools && 
				<TaskNav gameTasksData={gameTasksData} taskId={taskId} handleGoToTask={handleGoToTask} />
			}
		</div>
	);
};

Task.propTypes = {
	playerData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleBackgroundOffset: PropTypes.func.isRequired,
	setPage: PropTypes.func.isRequired,
	setStartGame: PropTypes.func.isRequired,
	updateModulePlayTimes: PropTypes.func.isRequired
};

export default Task;