import React from 'react';
import PropTypes from 'prop-types';
import './module-choice.scss';
import { getText } from 'helpers/text-helper';
import { sortArrayByProperty } from 'helpers/array-helper';
import { modulesData } from 'data/modules/modules-data';
import { themesData } from 'data/themes-data';
import ModuleOption from '../module-option/module-option';

const ModuleChoice = ({toggleTheme, toggleModule, selectedModuleIds, selectedThemeId, modules}) => {

	return (
		<div className="ModuleChoice-modulesWrap">
			{/* Selected modules */}
			<div className="ModuleChoice-modules selected">
				<div className="ModuleChoice-modulesTitle">
					<span>{getText('facilitatorUiTexts', 'selectedModules')}</span>
				</div>
				<div className="ModuleChoice-modulesSubTitle">
					<span></span>
				</div>
				<div className="ModuleChoice-modulesList">
					{selectedModuleIds && selectedModuleIds.map((moduleId) => {
						const moduleData = modulesData.find((m) => {return m.id === moduleId;});
						if (!moduleData) return null;
						return (
							<div 
								key={moduleId}
								className="ModuleChoice-module selected"
								onClick={() => {toggleModule(moduleId);}}
							>
								<span>{moduleData.title}</span>
							</div>
						);
					})}
				</div>
			</div>
			{/* Available modules */}
			<div className="ModuleChoice-modules available">
				<div className="ModuleChoice-modulesTitle">
					<span>{getText('facilitatorUiTexts', 'subjects')}</span>
				</div>
				<div className="ModuleChoice-modulesSubTitle">
					<span>{getText('facilitatorUiTexts', 'modulesInfo')}</span>
				</div>
				<div className="ModuleChoice-modulesList available">
					{themesData.map((themeData) => {
						return (
							<div key={themeData.id} 
								className={'ModuleChoice-theme' + (themeData.id === selectedThemeId ? ' open' : '')}
							>
								<div className="ModuleChoice-themeTitle" onClick={() => {toggleTheme(themeData.id);}}>
									<span>{themeData.title}</span>
								</div>
								<div className="ModuleChoice-themeModules">
									{sortArrayByProperty(modulesData.filter((m) => {
										return (
											m.themeId === themeData.id && selectedModuleIds.indexOf(m.id) < 0
										);
									}), 'title', 'ASC').map((moduleData) => {
										return (
											<ModuleOption key={moduleData.id}
												toggleModule={toggleModule}
												moduleData={moduleData}
												modules={modules}
											/>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

ModuleChoice.propTypes = {
	toggleTheme: PropTypes.func.isRequired,
	toggleModule: PropTypes.func.isRequired,
	selectedModuleIds: PropTypes.array,
	selectedThemeId: PropTypes.string,
	modules: PropTypes.array.isRequired
};

export default ModuleChoice;