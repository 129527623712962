const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		id: 'm2a-match-parts-use',
		text: 'Sæt dele af grisen sammen med de ting, de bliver brugt til.',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Hår fra grisen', 
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Blæren', 
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 3,
				option: 'Slim fra tarmene', 
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 4,
				option: 'Hud', 
				answers: [4]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Pensler',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Trommeskin',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Medicin',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Gelatine',
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm2a-match-hygiene-important',
		text: 'Det er vigtigt, at der er høj hygiejne og fødevaresikkerhed ude på slagterierne, hvorfor?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1, 2, 3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: []
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'På grund af lovkrav',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Fordi dårlig hygiejne kan gøre folk syge',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'For at sikre høj kvalitet',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2a-mc-code-of-practice',
		text: 'Code of practice er?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'De minimumskrav som Danish Crown stiller til deres grise-andelsejere, hvis de skal kunne levere dyr til Danish Crown',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'En del af den danske lovgivning og bekendtgørelse for svineproduktion',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'De koder man bruger i produktionen til at benævne de metoder man udskærer efter',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2a-mc-feed-control',
		text: 'Det er vigtigt, at der er kontrol med, hvilket foder grisene får ude ved landmanden, hvorfor?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Foder der er forurenet kan skade grisens helbred og dermed gøre forbrugerne syge',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Det kan skade grisens mave, og dermed sænke kvaliteten på kødet'
			})
		]
	})
];


let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}