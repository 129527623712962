const taskTemplates = require('data/templates/task-templates');

const highFivesData = [
  Object.assign({}, taskTemplates.highFiveTemplate.mainTemplate, {
    id: 'high-five-1',
    text: `Hvad med et high-five?`,
    text2: `Oh yeah!`
  })
];


export {
	highFivesData
}