import appConfig from 'config/app.config';
import {modulesData} from 'data/modules/modules-data';
import {factsData} from 'data/facts-data';
import {shuffleArray} from 'helpers/array-helper';
import {welcomeData} from 'data/welcome-data';
import {highFivesData} from 'data/high-fives-data';
import {multipleChoicePoints, matchPoints, sortPoints, orderPoints} from 'data/points-data';

/**
 * Get unplayed module tasks and facts, shuffle and group tasks and add facts.
 * Module tasks are displayed in equal sized groups interspersed with a fact.
 * For e.g. 8 module tasks and 3 facts, it should be:
 * task task | fact | task task | fact | task task | fact | task task
 * Surplus tasks are added to last task group.
 * Facts are treated as tasks of type "fact" by the task component.
 * @param {object} gameData 
 * @returns {array} gameTasksData
 */
export function getGameTasks(gameData, playerData) {
	/* Get selected module ids for game (if none selected, use all modules) */
	const gameModuleIds = (gameData.moduleIds
		? gameData.moduleIds
		: modulesData.map((module) => {return module.id;})
	);

	/* Get all tasks of selected modules */
	const gameTasksData = [];
	gameModuleIds.forEach((moduleId) => {
		const moduleData = modulesData.find((m) => {return m.id === moduleId;});
		if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
			moduleData.tasks.forEach((task) => {
				gameTasksData.push(task);
			});
		}
	});

	/* Get new (unplayed) tasks */
	let newTasks = ((!playerData || !playerData.tasks)
		? JSON.parse(JSON.stringify(gameTasksData))
		: gameTasksData.filter((task) => {
			return !playerData.tasks.some((t) => {return (t.taskId === task.id && t.isCompleted === true);});
		})
	);

	/* Shuffle new tasks */
	newTasks = shuffleArray(newTasks);

	/* Get selected fact ids for game (if none selected, use the X first) */
	const factIds = (gameData.factIds
		? gameData.factIds
		: factsData.slice(0, appConfig.gameFacts).map((fact) => {return fact.id;})
	);

	/* Special case: no facts */
	if (factIds.length === 0) {
		return newTasks;
	}

	/* If more facts than module tasks, limit number of used facts */
	const numberOfUsedFacts = Math.min(factIds.length, gameTasksData.length - 1);

	/* Get number of tasks in a group */
	const moduleTasksInAGroup = Math.floor(gameTasksData.length / (numberOfUsedFacts + 1));

	/* Get starting index of task (with respect to all tasks) */
	const startingTaskIndex = gameTasksData.length - newTasks.length;

	/* Get current index of group (with respect to all tasks) */
	let currentGroupIndex = Math.floor(startingTaskIndex / moduleTasksInAGroup);

	/* Get starting index of task (with respect to new tasks only) */
	let newTaskIndex = 0;

	/* Loop over new tasks */
	for (let i = startingTaskIndex; i < (moduleTasksInAGroup * numberOfUsedFacts + 1); i++) {
		const groupIndex = Math.floor(i / moduleTasksInAGroup);
		if (groupIndex > currentGroupIndex) {
			/* New module task group, add fact here (account for shifting caused by prev inserted facts) */
			const factData = factsData.find((f) => {return f.id === factIds[groupIndex - 1];});
			const factIndex = newTaskIndex + (groupIndex - Math.floor(startingTaskIndex / moduleTasksInAGroup) - 1);
			newTasks.splice(factIndex, 0, factData);
			currentGroupIndex = groupIndex;
		}
		newTaskIndex += 1;
	}

	/* Add high-five at midpoint */
	const midpointIndex = Math.floor(gameTasksData.length + numberOfUsedFacts) / 2;
	if (startingTaskIndex < midpointIndex) {
		newTasks.splice(midpointIndex - startingTaskIndex, 0, ...highFivesData);
	}
	
	/* Adding welcome message if player has not played yet */
	if (playerData && !playerData.tasks) {
		newTasks.splice(0, 0, ...welcomeData);
	}

	/* Add game over page */
	newTasks.push({id: 'gameover-1', type: 'gameover'});

	return newTasks;
}

/**
 * Gets all game tasks
 * @param {object} gameData 
 * @returns 
 */
export function getAllGameTasks(gameData) {
	/* Get selected module ids for game (if none selected, use all modules) */
	const gameModuleIds = (gameData.gameModuleIds
		? gameData.gameModuleIds
		: modulesData.map((module) => {return module.id;})
	);

	/* Get all tasks of selected modules */
	const gameTasksData = [];
	gameModuleIds.forEach((moduleId) => {
		const moduleData = modulesData.find((m) => {return m.id === moduleId;});
		if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
			moduleData.tasks.forEach((task) => {
				gameTasksData.push(task);
			});
		}
	});

	return gameTasksData;
}

/**
 * Get player tasks data
 * @param {object} playerData 
 * @returns 
 */
export function getPlayerTasksData(playerData) {
	let playerTasksData = [];
	if (playerData && playerData.tasks && playerData.tasks.length > 0) {
		playerTasksData = JSON.parse(JSON.stringify(playerData.tasks));
	}
	return playerTasksData;
}


/**
 * Get player task data from task id
 * @param {string} taskId 
 * @param {object} playerData 
 * @returns 
 */
export function getPlayerTaskData(taskId, playerData) {
	let playerTaskData = null;

	if (
		taskId && 
		playerData && 
		playerData.tasks && 
		playerData.tasks.length > 0
	) {
		playerTaskData = playerData.tasks.find((t) => {return t.taskId === taskId;});
	}

	return playerTaskData;
}

/**
 * Get max available points of task
 * @param {object} taskData 
 */
export function getMaxPointsOfTask(taskData) {
	let maxPoints = 0;

	if (taskData.type === 'multiple-choice') {
		maxPoints = Math.min(taskData.options.length, multipleChoicePoints.basePoints);
	}
	if (taskData.type === 'match') {
		maxPoints = matchPoints.pointValues[0];
	}
	if (taskData.type === 'sort') {
		maxPoints = sortPoints.pointValues[0];
	}
	if (taskData.type === 'order') {
		maxPoints = orderPoints.pointValues[0];
	}
	if (taskData.type === 'spot-errors') {
		maxPoints = taskData.errors ? taskData.errors.length : 0;
	}

	return maxPoints;
};

/**
 * Get max available points of all tasks in a game
 * @param {object} gameData 
 * @param {object} playerData 
 */
export function getMaxPointsOfGame(gameData, playerData) {
	/* Get selected module ids for game (if none selected, use all modules) */
	const gameModuleIds = (gameData.moduleIds
		? gameData.moduleIds
		: modulesData.map((module) => {return module.id;})
	);

	/* Get all tasks of selected modules */
	const gameTasksData = [];
	gameModuleIds.forEach((moduleId) => {
		const moduleData = modulesData.find((m) => {return m.id === moduleId;});
		if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
			moduleData.tasks.forEach((task) => {
				gameTasksData.push(task);
			});
		}
	});

	let maxPoints = 0;
	gameTasksData.forEach((gameTask) => {
		const taskMaxPoints = getMaxPointsOfTask(gameTask);
		if (taskMaxPoints) {
			maxPoints += taskMaxPoints;
		}
	});

	return maxPoints;
}