const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm7d-mc-3d-measure',
		text: 'Hvad bruges 3D-målinger til?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Trimning af kamme efter afsværing.',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Bedømme kvaliteten på de forskellige produkter',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Tjekke grisene for sygdomme inden de skæres op ude i produktionen.',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm7d-match-glove',
		text: 'Hvad er en løftehandske?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'En maskine der løfter grisene ind i produktionen.',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'En maskine der hjælper slagterimedarbejderne med at løfte de forskellige udskæringer.',
				isCorrect: true,
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm7d-mc-robot-function',
		text: 'Engang var arbejdet på slagteriet et manuelt hårdt fysisk arbejde. I dag bruges der i langt højere grad robotter. Hvilken funktion har tredeler robotten?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Afsaver spidsryggen fra midterstykket og deler i kam og bryst',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Deler den halverede gris i forende, midterstykke, skinke og bagtå',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Skærer tre koteletter ad gangen',
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}