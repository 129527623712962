/* Fact */
const factTemplate = {
	mainTemplate: {
		type: 'fact',
		isSolveToContinue: false,
		character: 'farmer',
		title: null,
		text: null
	}
};

/* High-five */
const highFiveTemplate = {
	mainTemplate: {
		type: 'high-five',
		isSolveToContinue: true,
		character: 'farmer-high-five',
		text: null,
		text2: null
	}
};

/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		subtype: null, // null, images
		isSolveToContinue: true,
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: 1,
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		image: null,
		text: '',
		options: [],
	},
	optionTemplate: {
		text: '', 
		effects: []
	}
};

/* Sort */
const sortTemplate = {
	mainTemplate: {
		type: 'sort',
		subtype: null, // null, images
		isSolveToContinue: true,
		showProgressBar: true,
		layout: '',
		text: null,
		image: null,
		categories: [],
		items: []	
	},
	categoryTemplate: {
		title: null,
		text: null,
	},
	itemTemplate: {
		text: null,
		categoryIds: []
	}
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		type: 'order',
		isSolveToContinue: true,
		showProgressBar: true,
		image: null,
		text: null,
		items: []
	},
	itemTemplate: {
		text: null,
		correctOrderNumbers: []
	}
};

/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		type: 'spot-errors',
		background: 'white',
		layout: '',
		isSolveToContinue: true,
		showProgressBar: true,
		doneWhenAllErrorsSpotted: false,
		showDefaultResultPopup: true,
		text: null,
		errors: [],
		doneEffects: []
	},
	errorTemplate: {
		effects: []
	}
};

/* match */
const matchTemplate = {
	mainTemplate: {
		title: '',
		type: 'match',
		layout: '',
		options: [],
		answers: [],
		text: '',
		assignmentNumber: 0,
		shuffleOptions: true,
	},
	optionTemplate: {
		option: '', 
		id: 0,
		answers: []
	},
	answerTemplate: {
		id: 0,
		isText: true,
		text: '',
	}
}

export {
	factTemplate,
	highFiveTemplate,
	sortTemplate,
	orderTemplate,
	spotErrorsTemplate,
	multipleChoiceTemplate,
	matchTemplate
};
