import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getText} from 'helpers/text-helper';
import './highscore.scss';

const Highscore = ({gameData}) => {
	/* Subscriptions */
	const unsubscribePlayersData = useRef(null);

	/* Loading status */
	const [isLoading, setIsLoading] = useState(true);

	/* Sorted players (by max points desc) */
	const [sortedPlayers, setSortedPlayers] = useState([]);

	/**
	 * Component did mount / component will unmount
	 */
	useEffect(() => {
		/* Subscribe to player data */
		subscribeToPlayersData().then(() => {
			setIsLoading(false);
		});
		
		/* Component will unmount */
		return () => {
			/* Cancel game players subscription */
			if (unsubscribePlayersData.current) unsubscribePlayersData.current();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Subscribe to today's players of game, order by points desc
	 * @returns {promise}
	 */
	const subscribeToPlayersData = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			unsubscribePlayersData.current = db.collection(appConfig.playersDbName)
				.where('gameId', '==', gameData.id).onSnapshot((querySnapshot) => {
					let players = [];
					if (!querySnapshot.empty) {
						querySnapshot.docs.forEach((doc) => {
							if (
								doc.data() && 
								doc.data().hasOwnProperty('totalPoints') &&
								doc.data().created === dayjs(Date.now()).format('YYYY-MM-DD')
							) {
								players.push({id: doc.id, ...doc.data()});
							}
						});
					}
					players = sortArrayByProperty(players, 'totalPoints', 'DESC');
					setSortedPlayers(players);
					resolve({status: 'success'});
				},
				(error) => {
					console.error('could not get games: ', error);
					resolve({status: 'error', error: error});
				});
		});
	};

	/* Limit to top X players */
	const topPlayers = (sortedPlayers.length > appConfig.highscoreMaxPlayers
		? sortedPlayers.slice(0, appConfig.highscoreMaxPlayers)
		: sortedPlayers
	);

	return (
		<div className="Highscore">
			{!isLoading && <div className="Highscore-panel">
				<div className="Highscore-wrap">
					<div className="Highscore-header"><span>{getText('gameUiTexts', 'scoreboard')}</span></div>
					<div className="Highscore-body">
						{topPlayers.map((player, index) => {
							return (
								<div key={index} className="Highscore-player">
									<span>{index + 1}.</span>
									<span>{player.playerName}</span>
									<span>{player.totalPoints}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>}
		</div>
	);
};

Highscore.propTypes = {
	gameData: PropTypes.object.isRequired,
};

export default Highscore;