import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import { isFullScreen, toggleFullScreen } from 'helpers/screen-helper';
import { getRandomNumber, getRandomWholeNumber } from 'helpers/math-helper';
import PlayerProgress from 'components/ui/player-progress/player-progress';
import PlayerPoints from 'components/ui/player-points/player-points';
import { soundsData } from 'data/sounds-data';
import appConfig from 'config/app.config';
import Highscore from './highscore/highscore';
import Task from './task/task';
import './game.scss';

const Game = ({
	playerData, 
	gameData, 
	scrollToTop, 
	updatePlayerData, 
	handleBackgroundOffset, 
	setStartGame, 
	isSoundOn, 
	setIsSoundOn,
	setShowCookieBanner,
	updateModulePlayTimes
}) => {
	/* Page (task or highscore) */
	const [page, setPage] = useState(null);

	/* Ref to audio player */
	const pigsAudioPlayerRef = useRef();
	const randomAudioPlayerRef = useRef();
	const audioInterval = useRef();
	const audioTimeout = useRef();
	const audioSrc = useRef(soundsData.backgroundSounds[0]);
	const [srcIndex, setSrcIndex] = useState(null); // Used to force reload and trigger audio play

	
	/* Check if fullscreen is available (is not for iOS on phone) */
	const doc = document;
	const showFullscreenBtn = !(
		doc.fullScreenElement === undefined && 
		doc.fullScreenElement === undefined &&
		doc.mozFullScreen === undefined &&
		doc.webkitIsFullScreen === undefined
	);
	
	/**
	 * Component did mount / component will unmount
	 */
	useEffect(() => {
		if (playerData.allTasksAreCompleted) {
			/* Player has completed all tasks, show highscore page */
			setPage('highscore');
		} else {
			/* Player has NOT completed all tasks */
			setPage('task');
		}
	
		audioInterval.current = setInterval(() => {
			if (pigsAudioPlayerRef.current) {
				pigsAudioPlayerRef.current.audio.current.play();
			}
		}, getRandomNumber(30, 45) * 1000);

		/* Component will unmount */
		return () => {
			if (audioInterval && audioInterval.current) {
				clearInterval(audioInterval.current);
			}
			if (audioTimeout && audioTimeout.current) {
				clearTimeout(audioTimeout.current);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Sets current sound to a random sound */
	const setRandomSound = () => {
		setSrcIndex(null);
		audioTimeout.current = setTimeout(() => {
			if (randomAudioPlayerRef.current) {
				const randomIndex = getRandomWholeNumber(0, soundsData.backgroundSounds.length);

				audioSrc.current = soundsData.backgroundSounds[randomIndex];
				setSrcIndex(randomIndex);
			}
		}, getRandomNumber(7, 13) * 1000);
	};

	useEffect(() => {
		if (srcIndex !== null && randomAudioPlayerRef.current) {
			randomAudioPlayerRef.current.audio.current.play();
		}
	}, [srcIndex]);

	/* Get page component */
	let PageComponent = Highscore;
	if (page === 'task') PageComponent = Task;
	
	return (
		<div className="Game">
			{isSoundOn &&
				<>
					<AudioPlayer
						ref={pigsAudioPlayerRef}
						muted={false}
						volume={appConfig.volume - 0.1}
						autoPlay={true}
						src={soundsData.pigs}
						style={{display: 'none'}}
					/>
					<AudioPlayer
						ref={randomAudioPlayerRef}
						muted={false}
						volume={appConfig.volume}
						autoPlay={true}
						src={audioSrc.current}
						style={{display: 'none'}}
						onEnded={() => {setRandomSound();}}
					/>
				</>
			}
			{/* Game header */}
			<div className="Game-header">
				{/* Points */}
				<div className='Game-headerPoints'>
					<PlayerPoints playerData={playerData} />
				</div>
				{/* Game progress */}
				<div className='Game-headerProgress'>
					<PlayerProgress playerData={playerData} gameData={gameData} />
				</div>
			</div>
			{/* Game content - task or highscore page */}
			<div className="Game-content">
				<PageComponent 
					playerData={playerData}
					gameData={gameData}
					updatePlayerData={updatePlayerData}
					scrollToTop={scrollToTop}
					setPage={setPage}
					handleBackgroundOffset={handleBackgroundOffset}
					setStartGame={setStartGame}
					updateModulePlayTimes={updateModulePlayTimes}
				/>
			</div>
			<div className="Game-fullscreenAndSoundBtns">
				{showFullscreenBtn && <div 
					className={'Game-fullscreenButton ' + (isFullScreen() ? 'exit' : 'open')}
					onClick={() => {toggleFullScreen();}}
				/>}
				<div className="Game-cookiesBtn" onClick={() => {setShowCookieBanner(true);}}/>
				<div 
					className={'Game-soundButton ' + (isSoundOn ? 'on' : 'off')}
					onClick={() => {setIsSoundOn(!isSoundOn);}}
				/>
			</div>
		</div>
	);
};

Game.propTypes = { 
	playerData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleBackgroundOffset: PropTypes.func.isRequired,
	setStartGame: PropTypes.func.isRequired,
	isSoundOn: PropTypes.bool.isRequired,
	setIsSoundOn: PropTypes.func.isRequired,
	setShowCookieBanner: PropTypes.func.isRequired,
	updateModulePlayTimes: PropTypes.func.isRequired,
};

export default Game;