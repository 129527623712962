import {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getCombinedTaskTime} from 'helpers/task-helper';
import {getAveragePlayTime} from 'helpers/module-helper';
import {factsData} from 'data/facts-data';
import {modulesData} from 'data/modules/modules-data';
import Button from 'components/ui/button/button';
import QR from 'components/ui/qr/qr';
import FacilitatorDeleteGamePopup from './facilitator-delete-game-popup';
import './facilitator-game.scss';

const FacilitatorGame = ({selectedGameData, selectedGamePlayers, setSelectedGameId, deleteGame}) => {
	/* Show "delete game"-popup */
	const [showDeleteGamePopup, setShowDeleteGamePopup] = useState(false);

	/* Get completed players for highscore list */
	let highscorePlayers = selectedGamePlayers.filter((p) => {
		return (
			p.hasOwnProperty('totalPoints') &&
			p.allTasksAreCompleted === true 
		);
	});
	highscorePlayers = sortArrayByProperty(highscorePlayers, 'totalPoints', 'DESC');
	if (highscorePlayers.length > appConfig.highscoreMaxPlayers) {
		highscorePlayers.slice(0, appConfig.highscoreMaxPlayers);
	}

	/* Get selected modules & facts data */
	const selectedModulesData = modulesData.filter((moduleData) => {
		return (
			selectedGameData && 
			selectedGameData.moduleIds && 
			selectedGameData.moduleIds.indexOf(moduleData.id) >= 0
		);
	});
	const selectedFactsData = factsData.filter((factData) => {
		return (
			selectedGameData && 
			selectedGameData.factIds &&
			selectedGameData.factIds.indexOf(factData.id) >= 0
		);
	});

	/* Get stats */
	const startedGames = selectedGamePlayers.length; // TODO: only started games today?
	const completedGames = selectedGamePlayers.filter((p) => {return p.allTasksAreCompleted === true;}).length;
	const averageTime = getAveragePlayTime(selectedGamePlayers);

	let stringTime = '-';

	if (averageTime) {
		const seconds = Math.floor(averageTime / 1000);
		const minutes = Math.floor(seconds / 60);
		const shownSeconds = seconds % 60;

		stringTime = String(minutes).padStart(2, '0') + ':' + String(shownSeconds).padStart(2, '0');
	}

	return (
		<div className="FacilitatorGame">
			{/* Facts, modules, highscore & stats */}
			<div className="FacilitatorGame-wrap">
				{/* Facts */}
				<div className="FacilitatorGame-infoGroup">
					<div className="FacilitatorGame-info facts">
						<div className="FacilitatorGame-infoTitle">
							<span>{getText('facilitatorUiTexts', 'didYouKnow')}</span>	
						</div>
						<div className="FacilitatorGame-infoText">
							<span>{getText('facilitatorUiTexts', 'selected')}:</span>	
						</div>
						<div className="FacilitatorGame-facts">
							{selectedFactsData.map((factData, index) => {
								return (
									<div key={factData.id} className="FacilitatorGame-fact">
										<span>{index + 1}</span>
										<span>{factData.text}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				{/* Modules */}
				<div className="FacilitatorGame-infoGroup">
					<div className="FacilitatorGame-info modules">
						<div className="FacilitatorGame-infoTitle">
							<span>{getText('facilitatorUiTexts', 'modules')}</span>	
						</div>
						<div className="FacilitatorGame-infoText">
							<span>{getText('facilitatorUiTexts', 'selected')}:</span>
						</div>
						<div className="FacilitatorGame-modules">
							{selectedModulesData.map((moduleData, index) => {
								return (
									<div key={moduleData.id} className="FacilitatorGame-module">
										<span>{index + 1}</span>
										<span>{moduleData.title}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				
				{/* Highscore */}
				<div className="FacilitatorGame-infoGroup">
					<div className="FacilitatorGame-info highscore">
						<div className="FacilitatorGame-infoTitle">
							<span>{getText('gameUiTexts', 'scoreboard')}</span>
						</div>
						<div className="FacilitatorGame-infoText">
							<span>{getText('facilitatorUiTexts', 'forAllPlays')}:</span>	
						</div>
						<div className="FacilitatorGame-highscore">
							<div className="FacilitatorGame-highscoreRow header">
								<span>#</span>
								<span>{getText('facilitatorUiTexts', 'player')}</span>
								<span>{getText('facilitatorUiTexts', 'point')}</span>
								<span>{getText('facilitatorUiTexts', 'playTime')}</span>
							</div>
							{highscorePlayers.map((p,  index) => {
								// Get total time as seconds
								const totalTime = getCombinedTaskTime(p.tasks);
								const seconds = Math.floor(totalTime / 1000);
								const minutes = Math.floor(seconds / 60);
								const shownSeconds = seconds % 60;
								const stringTime = 
									String(minutes).padStart(2, '0') + ':' + String(shownSeconds).padStart(2, '0');
								return (
									<div key={index} className="FacilitatorGame-highscoreRow">
										<span>{index + 1}</span>
										<span>{p.playerName}</span>
										<span>{p.totalPoints}</span>
										<span>{stringTime}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				{/* Stats & Location */}
				<div className="FacilitatorGame-infoGroup">
					{/* Stats */}
					<div className="FacilitatorGame-info stats">
						<div className="FacilitatorGame-infoTitle">
							<span>{getText('facilitatorUiTexts', 'stats')}</span>	
						</div>
						<div className="FacilitatorGame-infoText">
							<span>{getText('facilitatorUiTexts', 'forGameActivity')}:</span>	
						</div>
						<div className="FacilitatorGame-stats">
							<div className="FacilitatorGame-stat started">
								<span>{getText('facilitatorUiTexts', 'startedGames')}:</span>
								<span>{startedGames}</span>
							</div>
							<div className="FacilitatorGame-stat completed">
								<span>{getText('facilitatorUiTexts', 'completedGames')}:</span>
								<span>{completedGames}</span>
							</div>
							<div className="FacilitatorGame-stat completed">
								<span>{getText('facilitatorUiTexts', 'avrCompletionTime')}:</span>
								<span>{stringTime}</span>
							</div>
						</div>
					</div>

					{/* Location (URL and QR code) */}
					<div className="FacilitatorGame-info location">
						{selectedGameData && <div className="FacilitatorGame-location">
							<div className="FacilitatorGame-url">
								<a 
									href={'http' + (appConfig.env !== 'development' ? 's' : '') + '://' + appConfig.gameUrl + '/' + selectedGameData.id}
									target="_blank"
									rel="noreferrer"
								>
									{appConfig.gameUrl}/{selectedGameData.id}
								</a>
							</div>
							<QR url={'http' + (appConfig.env !== 'development' ? 's' : '') + '://' + appConfig.gameUrl + '/' + selectedGameData.id} />
						</div>}
					</div>
				</div>
			</div>
			
			

			{/* Back btn */}
			<div className="FacilitatorGame-backBtn">
				<Button 
					classes={['facilitator']}
					text={getText('facilitatorUiTexts', 'back')}
					onClick={() => {setSelectedGameId(null);}}
				/>
			</div>

			{/* Delete game btn */}
			<div className="FacilitatorGame-deleteGameBtn">
				<Button 
					classes={['facilitator']}
					text={getText('facilitatorUiTexts', 'deleteGame')}
					onClick={() => {setShowDeleteGamePopup(true);}}
				/>
			</div>

			{/* Delete game popup */}
			{showDeleteGamePopup && 
				<FacilitatorDeleteGamePopup 
					setShowDeleteGamePopup={setShowDeleteGamePopup}
					deleteGame={deleteGame}
					setSelectedGameId={setSelectedGameId}
				/>
			}
		</div>
	);
};

FacilitatorGame.propTypes = {
	selectedGameData: PropTypes.object.isRequired,
	selectedGamePlayers: PropTypes.array.isRequired,
	setSelectedGameId: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
};

export default FacilitatorGame;