/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyCcYeZB6PFqO0BOR3gpszLEdpgbwLOkW7g',
		authDomain: 'cgl-mobil-gris-test.firebaseapp.com',
		projectId: 'cgl-mobil-gris-test',
		storageBucket: 'cgl-mobil-gris-test.appspot.com',
		messagingSenderId: '684673831642',
		appId: '1:684673831642:web:03c61ac6d66f1a5a460be3'
	};
}
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyAaKval4tAvP8_RwHYQltJ5vWzrOypb6bU',
		authDomain: 'cgl-mobil-gris-production.firebaseapp.com',
		projectId: 'cgl-mobil-gris-production',
		storageBucket: 'cgl-mobil-gris-production.appspot.com',
		messagingSenderId: '917519922869',
		appId: '1:917519922869:web:a0983975f5eadc49bf70fc'
	};
}
 
/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;