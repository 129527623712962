const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm1e-mc-hotdog-cart',
		text: 'Hvor mange pølsevogne var der i Danmark tilbage i 1960’erne-1970érne og hvor mange er der tilbage i dag?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Dengang var der 1000 pølsevogne i Danmark. I dag er der kun omkring 200 tilbage'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Dengang var der 400 pølsevogne i Danmark. I dag er der kun omkring 50 tilbage'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Dengang var der 700 pølsevogne i Danmark. I dag kun er omkring 100 tilbage',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm1e-match-meat-product',
		text: 'Hvad betyder dét at forædle et kødprodukt?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [1, 3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'At detailskære kødprodukter',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'At bearbejde en råvare industrielt til nye og mere attraktive produkter',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'At bearbejde kødprodukter til færdigretter der kan købes i supermarkederne',
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm1e-mc-filet-royal',
		text: 'Hvor på grisen sidder udskæringen Filet Royal',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Filet Royal skæres af det midterste stykke på kammen',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Filet Royal skæres af grisens nakke'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Filet Royal skæres af bagenden på grisen, kaldet skinken'
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm1e-mc-bacon',
		text: `Hvad er forskellen på dansk bacon og 'engelsk bacon'?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: `Den danske bacon fremstilles af skinken på grisen, mens det 'engelsk bacon' skæres fra kammen`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `Den danske bacon og det 'engelske bacon' skæres begge fra kammen, men på det 'det engelsk bacon' er flæsket røget`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Den danske bacon fremstilles af bryststykket, mens det 'engelsk bacon' skæres fra kammen`,
				isCorrect: true
			}),
		],
	}),
];


let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}