import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import TaskIntro from '../task-intro/task-intro';
import { shuffleArray } from 'helpers/array-helper';
import { multipleChoicePoints } from 'data/points-data';
import './multiple-choice.scss';

const MultipleChoice = (props) => {
	const {
		taskData, 
		handleCompleteTask,
		playerTaskData,
		updateLoggedTime
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);
	
	/* Get number of correct answers */
	const numberOfCorrectAnswers = (taskData.isDilemma
		? taskData.numberOfAnswersToSelect
		: taskData.options ? taskData.options.filter((o) => {return o.isCorrect === true;}).length : 0
	);

	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!taskData.options) return [];
		if (taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			return taskData.options.map((option) => {return option.id;});
		}
		return shuffleArray(taskData.options.map((option) => {return option.id;}));
	};

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSelectedOptionIds = () => {
		let optionIds = [];
		if (playerTaskData && playerTaskData.taskData) {
			optionIds = playerTaskData.taskData;
		}
		return optionIds;
	};

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);
	
	/* Update selected items if new task */
	useEffect(() => {
		setOptionIds(getOptionIds());
		setSelectedOptionIds(getSelectedOptionIds());

		const optionsElement = document.getElementById('multipleChoiceOptions');
		if (optionsElement) optionsElement.scrollTop = 0;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);


	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Update logged time */
		updateLoggedTime();
		
		/* Already completed */
		if (isCompleted === true) return;

		/* Already selected */
		const optionIndex = selectedOptionIds.indexOf(optionId);
		if (optionIndex >= 0) return;


		/* Select option */
		let newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		newSelectedOptionIds.push(optionId);
		setSelectedOptionIds(newSelectedOptionIds);
		
		/* Check if task is completed */
		const taskIsCompleted = checkIfAllCorrectOptionsAreSelected(newSelectedOptionIds);

		if (taskIsCompleted) {
			/* Complete task */
			completeTask(newSelectedOptionIds);
		} 
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 * @param {number} newPoints
	 */
	const completeTask = (newSelectedOptionIds) => {
		
		/* Get number of wrong answers */
		let wrongAnswers = 0;
		newSelectedOptionIds.forEach((selectedOptionId) => {
			if (!taskData.options.some((optionData) => {
				return (optionData.id === selectedOptionId && optionData.isCorrect === true);
			})) {
				wrongAnswers += 1;
			}
		});


		/* Calculate points */
		let updatedPoints = 0;
		const maxPoints = Math.min(taskData.options.length, multipleChoicePoints.basePoints);
		updatedPoints = Math.max(
			multipleChoicePoints.minimumPoints, 
			maxPoints - (wrongAnswers * multipleChoicePoints.minusPointsPerWrongAnswers)
		);

		/* Save completed task */
		handleCompleteTask(
			updatedPoints,
			wrongAnswers, 
			newSelectedOptionIds
		);
	};


	/**
	 * Check if all correct options are selected (i.e. if task is complete)
	 * @param {array} newSelectedOptionIds 
	 * @returns 
	 */
	const checkIfAllCorrectOptionsAreSelected = (newSelectedOptionIds) => {
		let allCorrectOptionsAreSelected = true;
		taskData.options.forEach((optionData) => {
			if (optionData.isCorrect === true && newSelectedOptionIds.indexOf(optionData.id) < 0) {
				allCorrectOptionsAreSelected = false;
			}
		});
		return allCorrectOptionsAreSelected;
	};

	return (
		<div className={'MultipleChoice'
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')
		}
		>
			<div className="MultipleChoice-content">
				<div className="MultipleChoice-intro">
					<TaskIntro
						showNumberOfAnswersToSelect={taskData.showNumberOfAnswersToSelect}
						numberOfCorrectAnswers={numberOfCorrectAnswers}
						taskId={taskData.id}
						text={taskData.text}
						image={taskData.image}
					/>
					
				</div>
				
				{/* options */}
				<div id="multipleChoiceOptions" className="MultipleChoice-options">
					<div className={'MultipleChoice-optionsWrap'}>
						{optionIds.map((optionId, index) => {
							const optionData = taskData.options.find((option) => {return option.id === optionId;});
							if (!optionData) return null;
							const isSelected = selectedOptionIds.indexOf(optionData.id) >= 0;
							let optionClass = 'MultipleChoice-option';
							let buttonClass = 'blue';

							/* overwrite button class if selected as correct or wrong */
							if (isSelected && optionData.isCorrect) {
								buttonClass = 'green';
								buttonClass += ' correct';
							} else if (isSelected && !optionData?.isCorrect) {
								buttonClass = 'red';
								buttonClass += ' wrong';
							}

							if (taskData.layout) {
								optionClass += ' option-' + optionData.id + ' position-' + (index + 1);
							}
							/* Disabled the buttons if task is complete */
							if (isCompleted) {
								buttonClass += ' disabled';
								optionClass += ' disabled';
							}

							return (
								<div 
									key={optionData.id} 
									className={optionClass} 
								>
									<Button 
										classes={[buttonClass]}
										text={optionData.text}
										onClick={() => {selectOptionId(optionData.id);}}
									/>
								</div>
							);
						})}
					</div>
				</div>

			</div>
		</div>
	);
};

MultipleChoice.propTypes = {
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	playerTaskData: PropTypes.object,
	updateLoggedTime: PropTypes.func.isRequired
};

export default MultipleChoice;
