import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {orderPoints} from 'data/points-data';
import {shuffleArray, sortArrayByProperty} from 'helpers/array-helper';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import OrderDndItems from './order-dnd-items';
import TaskIntro from '../task-intro/task-intro';
import Popup from 'components/ui/popup/popup';
import './order.scss';

const Order = (props) => {
	const {
		playerTaskData, 
		taskData,
		handleCompleteTask,
		updateLoggedTime
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Get items to be ordered */
	const getOrderedItemIds = () => {
		/* Get items from player data or data file */
		let items = null;
		
		if (playerTaskData && playerTaskData.orderedItems) {
			items = playerTaskData.orderedItems;
		} else {
			// Shuffling first, before making list of items, to make sure location is in order
			const tempArray = shuffleArray(taskData.items);
			items = tempArray.map((item, index) => {
				return {id: item.id, isLocked: false, location: index};
			});
		}

		/* Special case: if facilitator and completed, show correct order */
		if (isCompleted) {
			items = taskData.items.map((item, index) => {return {id: item.id, isLocked: false, location: index};});
		}
		return items;
	};

	/* Track ordered item ids (current and prev round) and number of rounds */
	const [orderedItems, setOrderedItems] = useState([]);
	const [previewItems, setPreviewItems] = useState(null);
	/* Used to track what current preview is based on */
	const [currentPreview, setCurrentPreview] = useState(null);
	const [roundNumber, setRoundNumber] = useState(1);

	/* Popup with number of correct positions */
	const [popupData, setPopupData] = useState(null);

	/* Update sorted items if new task */
	useEffect(() => {
		setOrderedItems(getOrderedItemIds());
		setRoundNumber(1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Switch items
	 * @param {number} fromIndex 
	 * @param {number} toIndex 
	 */
	const handleSwitchItems = () => {
		if (!previewItems) {
			return;
		}

		/* Move order of items */
		const newItems = [];

		previewItems.forEach((preview) => {
			newItems.push({id: preview.item.id, isLocked: preview.isLocked, location: preview.location});
		});

		/* Update logged time */
		updateLoggedTime();

		setPreviewItems(null);
		setCurrentPreview(null);
		setOrderedItems(newItems);
	};

	/**
	 * Sets a preview list with what the list should look like after switching order
	 */
	const handleSetPreview = (fromLocation, toLocation) => {
		setCurrentPreview({fromLocation: fromLocation, toLocation: toLocation});
		
		let items = null;
		if (previewItems) {
			items = JSON.parse(JSON.stringify(previewItems));
		} else {
			items = JSON.parse(JSON.stringify(orderedItems));
		}

		let preview = [];

		items.forEach((item, index) => {
			let newLocation = index;
			
			if (item.isLocked) {
				newLocation = index;
			} else if (index === fromLocation) {
				newLocation = toLocation;
			} else if (fromLocation < toLocation) { // Moving down
				// Index is past start location but before end location
				if (index > fromLocation && index <= toLocation) {
					// find next viable location
					for (let i = index - 1; i >= 0; i--) {
						if (!items[i].isLocked) { // Item is not locked, thus its location is usable
							newLocation = i;
							break;
						}
					}
				}
			} else if (fromLocation > toLocation) { // Moving up
				// Index is before start location but past end location
				if (index < fromLocation && index >= toLocation) {
					// find next viable location
					for (let i = index + 1; i < items.length; i++) {
						if (!items[i].isLocked) { // Item is not locked, thus its location is usable
							newLocation = i;
							break;
						}
					}
				}
			}

			preview.push({id: item.id, item: item, location: newLocation, isLocked: item.isLocked});
		});

		preview = sortArrayByProperty(preview, 'location', 'ASC');

		setPreviewItems(preview);
	};

	/**
	 * Confirm order
	 */
	const confirmOrder = () => {
		/* Count errors */
		let errors = 0;
		orderedItems.forEach((item, index) => {
			const itemData = taskData.items.find((i) => {return i.id === item.id;});
			if (itemData && itemData.correctOrderNumbers && itemData.correctOrderNumbers.indexOf(index + 1) < 0) {
				errors += 1;
			}
		});

		if (errors > 0) {
			/* Show popup */
			const popupTextNumber = (roundNumber < 2 ? roundNumber : 2);
			const popupText = getText('gameUiTexts', ('orderPopupText' + popupTextNumber));
			const popupTitle = getText('gameUiTexts', 'orderPopupTitle')
				.replace('%correctAnswers%', (orderedItems.length - errors))
				.replace('%totalAnswers%', orderedItems.length);
			setPopupData({
				title: popupTitle,
				text: popupText
			});
		} else {
			/* Complete task */
			completeTask();
		}
	};

	/**
	 * Close popup
	 */
	const handleClosePopup = () => {
		/* Update round number */
		const newRoundNumber = roundNumber + 1;
		setRoundNumber(newRoundNumber);

		/* Lock correctly placed items if round >= 1 */
		if (newRoundNumber >= 1) {
			let newOrderedItemIds = JSON.parse(JSON.stringify(orderedItems));
			newOrderedItemIds.forEach((item, index) => {
				const itemData = taskData.items.find((i) => {return i.id === item.id;});
				if (itemData && itemData.correctOrderNumbers && itemData.correctOrderNumbers.indexOf(index + 1) >= 0) {
					item.isLocked = true;
				}
			});
			setOrderedItems(newOrderedItemIds);
		}

		/* Close popup */
		setPopupData(null);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Errors */ 
		const errors = (roundNumber - 1);
		
		/* Calculate points */
		let points = orderPoints.minPoints;
		let pointIndex = orderPoints.pointLimits.findIndex((limit) => {return errors <= limit;});
		if (pointIndex >= 0) points = orderPoints.pointValues[pointIndex];

		/* Save completed task */
		handleCompleteTask(
			points, 
			errors,
			{orderedItems: orderedItems}
		);
	};

	/**
	 * Unsets preview list
	 */
	const handleUnsetPreview = () => {
		setPreviewItems(null);
	};

	return (
		<div className={'Order'}>
			<div id="taskIntro" className="Order-intro">
				<TaskIntro
					taskId={taskData.id}
					text={taskData.text}
					image={taskData.image}
				/>
			</div>

			<OrderDndItems 
				handleSwitchItems={handleSwitchItems} 
				isCompleted={isCompleted}
				itemList={orderedItems}
				previewList={previewItems}
				currentPreview={currentPreview}
				taskData={taskData}
				handleSetPreview={handleSetPreview}
				handleUnsetPreview={handleUnsetPreview}
			/>

			{/* Done button */}
			{!isCompleted && <div className="Order-doneBtn">
				<Button
					classes={['blue', 'done']}
					text={getText('gameUiTexts', 'ok')}
					onClick={confirmOrder}
				/>
			</div>}

			{popupData && <Popup 
				type="order-result"
				title={popupData.title}
				text={popupData.text}
				togglePopup={handleClosePopup}
			/>}
		</div>
	);
};

Order.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired
};

export default Order;
