import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './task-nav.scss';

const TaskNav = ({gameTasksData, taskId, handleGoToTask}) => {
	
	/* Track if open / closed */
	const [isOpen, setIsOpen] = useState(false);

	/* Get current task index */
	const selectedTaskIndex = gameTasksData.findIndex((task) => {return task.id === taskId;});

	/* On open, autoscroll to current task on list */
	useEffect(() => {
		if (isOpen) {
			const taskElements = document.getElementsByClassName('TaskNav-item selected');
			if (taskElements) {
				taskElements[0].scrollIntoView(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<div className={'TaskNav' + (isOpen ? ' open' : '')} onClick={() => {setIsOpen(!isOpen);}}>
			{isOpen && <div className="TaskNav-items">
				{gameTasksData.map((task, index) => {
					return (
						<div 
							key={task.id} 
							className={'TaskNav-item' + 
								(index < selectedTaskIndex ? ' prev' : '') +
								(task.id === taskId ? ' selected' : '')
							}
							onClick={() => {handleGoToTask(task.id);}}
						>{task.id} <span>({task.type + (task.subtype ? ' ' + task.subtype : '')})</span></div>
					);
				})}
			</div>}
			<div className="TaskNav-toggleBtn" />
		</div>
	);
};

TaskNav.propTypes = {
	gameTasksData: PropTypes.array.isRequired,
	taskId: PropTypes.string.isRequired,
	handleGoToTask: PropTypes.func.isRequired,
};

export default TaskNav;
