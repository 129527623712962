const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm5d-match-boar-taming',
		text: 'Hvordan blev vildsvinet tamt?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Sandt',
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Falsk',
				answers: [2, 3]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Mennesker fodrede dem med affald eller rystede et frugttræ, så frugterne faldt ned til svinene.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Mennesker begyndte at tage vildsvinenes smågrise ind i husholdningerne.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Mennesker fangede dem og holdt dem i indhegninger.',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm5d-mc-wild-pigs',
		text: 'For 1600 år siden vandrede grisene frit rundt i skovene - hvorfor satte man dem i stald?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'I 1600-tallet begyndte grisekød at blive en dyr handelsvare. For at sikre, at grisene ikke løb væk begyndte landmændene at have grisene i indhegning',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'I 1660 besluttede den danske kongen, at bønderne skulle betale en afgift for at have grisene til at gå i skoven. Derfor begyndte bønderne at bygge grisestier med hegn for at holde grisene inde.',
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm5d-match-pig-characteristics',
		text: 'Hvilke karakteristika passer til hvilken griserace?',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Duroc',
				answers: [1, 5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Yorkshire',
				answers: [2, 4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 3,
				option: 'Landracen',
				answers: [3, 6]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Rødbrun med mørke børster',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Lang krop og ører der står lige op.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Lyserød hud og store øre der hænger ned foran øjnene.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Magert kød.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				text: 'Giver mindre kød end de andre griseracer, men vokser hurtigere.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				text: 'Har meget kød på kroppen.',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm5d-mc-popular-pig-race',
		text: 'Hvilken griserace er meget populær i udlandet, også som avlsdyr?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Landracen',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Duroc',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Yorkshire',
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}