import PropTypes from 'prop-types';
import { getGameTasks } from 'helpers/game-helper';
import appConfig from 'config/app.config';
import './player-progress.scss';

const PlayerProgress = ({playerData, gameData}) => {

	const gameTasks = getGameTasks(gameData, playerData);

	const factsLeft = gameTasks.filter((tasks) => {
		return (tasks.type === 'fact' && !tasks.id.includes('welcome')) || tasks.type === 'gameover';
	}).length;

	let factCount = appConfig.gameFacts + 1;

	let lastTaskCompleted = 0;
	if (playerData.allTasksAreCompleted) {
		lastTaskCompleted = 1;
	}

	const completedFacts = factCount - factsLeft + lastTaskCompleted;

	return (
		<div className="PlayerProgress">
			<div className="PlayerProgress-wrap">
				<div className="PlayerProgress-points">
					<div className='PlayerProgress-bar'>
						<div className='PlayerProgress-startBall'>
							<div className='PlayerProgress-startBallInner'/>
						</div>
						{[...Array(factCount)].map((circle, index) => {
							const isCompleted = completedFacts > index;
							const isCurrent = completedFacts - 1 === index;

							return (
								<div key={index} className='PlayerProgress-partWrapper'>
									<div className={'PlayerProgress-barPart ' + (isCompleted ? 'completed' : '')}/>
									<div className='PlayerProgress-barPart light slim onTop'/>

									<div className={'PlayerProgress-ballPart ' + (isCompleted ? 'completed' : '')}>
										<div className='PlayerProgress-ballInner'/>
										{isCurrent &&
											<div className='PlayerProgress-ballInnermost' />
										}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

PlayerProgress.propTypes = {
	playerData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
};

export default PlayerProgress;