import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import BirdGenerator from 'components/bird-generator/bird-generator';
import './background.scss';

const Background = ({offsetMultiplier, isSoundOn}) => {
	const backDelay = useRef(null);
	const middleDelay = useRef(null);
	const foreDelay = useRef(null);

	const [forceUpdate, setForceUpdate] = useState(false); // used to force update on delay

	const [currentOffset, setCurrentOffset] = useState(0);

	const backgroundMultiplier = 0.25;
	const middlegroundMultiplier = 0.5;

	const stepSize = 62.5;
	const imageLength = 124.9; // em

	const [backgrounds] = useState(
		[
			{
				initialPosition: 0,
				isShown: true,
			},
			{
				initialPosition: imageLength,
				isShown: true,
			}
		]
	);
	const [middlegrounds] = useState(
		[
			{
				initialPosition: 0,
				isShown: true,
			},
			{
				initialPosition: imageLength,
				isShown: true,
			}
		]
	);
	const [foregrounds] = useState(
		[
			{
				initialPosition: 0,
				isShown: true,
			},
			{
				initialPosition: imageLength,
				isShown: true,
			}
		]
	);

	useEffect(() => {
		handleOffset(offsetMultiplier);

		/* Component will ummount */
		return () => {
			if (backDelay.current) {
				clearTimeout(backDelay);
			}
			if (middleDelay.current) {
				clearTimeout(middleDelay);
			}
			if (foreDelay.current) {
				clearTimeout(foreDelay);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offsetMultiplier]);

	/**
	 * Sets new offset for parallax background. Handles resetting backgrounds.
	 * @param {number} multiplier
	 */
	const handleOffset = (multiplier) => {
		const newOffset = stepSize * multiplier;
		
		backgrounds.forEach((back) => {
			if (back.initialPosition - newOffset * backgroundMultiplier <= -(imageLength)) {
				const newDelay = setTimeout(() => {
					back.initialPosition += imageLength * 2;
					back.isShown = false;
					setForceUpdate(true);
				}, 1000);
				backDelay.current = newDelay;
				setForceUpdate(false);
			} else {
				back.isShown = true;
			}
		});
		
		middlegrounds.forEach((middle) => {
			if (middle.initialPosition - newOffset * middlegroundMultiplier <= -(imageLength)) {
				const newDelay = setTimeout(() => {
					middle.initialPosition += imageLength * 2;
					middle.isShown = false;
					setForceUpdate(true);
				}, 1000);
				middleDelay.current = newDelay;
				setForceUpdate(false);
			} else {
				middle.isShown = true;
			}
		});

		foregrounds.forEach((fore) => {
			if (fore.initialPosition - newOffset <= -(imageLength)) {
				const newDelay = setTimeout(() => {
					fore.initialPosition += imageLength * 2;
					fore.isShown = false;
					setForceUpdate(true);
				}, 1000);
				foreDelay.current = newDelay;
				setForceUpdate(false);
			} else {
				fore.isShown = true;
			}
		});

		setCurrentOffset(newOffset);
	};

	return (
		<div className='Background'>
			{backgrounds && backgrounds.map((image, index) => {
				return (
					<div key={index} className={'Background-image back ' + (image.isShown ? 'isShown' : '')} 
						style={{
							transform: 'translate(' + 
								(image.initialPosition - currentOffset * backgroundMultiplier - 10) + 'em, 0)',
							OTransform: 'translate(' + 
								(image.initialPosition - currentOffset * backgroundMultiplier - 10) + 'em, 0)',
							msTransform: 'translate(' + 
								(image.initialPosition - currentOffset * backgroundMultiplier - 10) + 'em, 0)',
							MozTransform: 'translate(' + 
								(image.initialPosition - currentOffset * backgroundMultiplier - 10) + 'em, 0)',
							WebkitTransform: 'translate(' + 
								(image.initialPosition - currentOffset * backgroundMultiplier - 10) + 'em, 0)',
						}}
					/>
				);
			})}
			{middlegrounds && middlegrounds.map((image, index) => {
				return (
					<div key={index} className={'Background-image middle ' + (image.isShown ? 'isShown' : '')} 
						style={{
							transform: 'translate(' + 
								(image.initialPosition - currentOffset * middlegroundMultiplier) + 'em, 0)',
							OTransform: 'translate(' + 
								(image.initialPosition - currentOffset * middlegroundMultiplier) + 'em, 0)',
							msTransform: 'translate(' + 
								(image.initialPosition - currentOffset * middlegroundMultiplier) + 'em, 0)',
							MozTransform: 'translate(' + 
								(image.initialPosition - currentOffset * middlegroundMultiplier) + 'em, 0)',
							WebkitTransform: 'translate(' + 
								(image.initialPosition - currentOffset * middlegroundMultiplier) + 'em, 0)',
						}}
					/>
				);
			})}
			{foregrounds && foregrounds.map((image, index) => {
				return (
					<div key={index} className={'Background-image fore ' + (image.isShown ? 'isShown' : '')} 
						style={{
							transform: 'translate(' + (image.initialPosition - currentOffset - 20) + 'em, 0)',
							OTransform: 'translate(' + (image.initialPosition - currentOffset - 20) + 'em, 0)',
							msTransform: 'translate(' + (image.initialPosition - currentOffset - 20) + 'em, 0)',
							MozTransform: 'translate(' + (image.initialPosition - currentOffset - 20) + 'em, 0)',
							WebkitTransform: 'translate(' + (image.initialPosition - currentOffset - 20) + 'em, 0)',
						}}
					/>
				);
			})}

			<BirdGenerator isSoundOn={isSoundOn}/>
			<div className='Background-foreground tree'/>
		</div>
	);
};

Background.propTypes = {
	offsetMultiplier: PropTypes.number.isRequired,
	isSoundOn: PropTypes.bool.isRequired,
};

export default Background;