const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm5c-mc-original-pig',
		text: 'For 3-6000 år siden kom tamgrisen til Danmark - hvorfra kom den oprindeligt?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Østeuropa',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Sydeuropa',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Mellemøsten',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm5c-mc-bi-product',
		text: 'Hvor eksporteres de fleste af biprodukterne fra grisen hen?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Japan',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Spanien',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Tyskland',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Kina',
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm5c-mc-export-count',
		text: 'Hvor mange lande eksporterer Danmark gris til på verdensplan?',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '96 lande',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '127 lande',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '132 lande',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '140 lande',
			}),
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm5c-match-characteristics-pigs',
		text: 'Hvilke karakteristika passer til enten vildsvinet eller grisen?',
		layout: 'sortType',
		subtype: 'blink',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'Vildsvin',
				answers: [1, 2, 5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'Gris',
				answers: [3, 4, 6]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Vejer 180 kg når den er voksen',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Mørk og kraftig pels',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Kan veje op til 450 kg.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Næsten ingen pels',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				text: '110-180 cm. lang',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				text: 'I gennemsnit 184 cm. lang',
			}),
		],
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}