import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './order-dnd-preview.scss';

const OrderDndPreview = ({orderedItems, itemsData}) => {
	const [touchPositionX, setTouchPositionX] = useState(null);

	const [isHoldingRight, setIsHoldingRight] = useState(false);

	useEffect(() => {
		/* Setting event listeners */
		const itemsDiv = document.getElementById('orderItems');
		
		itemsDiv.addEventListener(
			'touchstart',
			(e) => {
				if (!touchPositionX) {
					setTouchPositionX(e.touches[0].clientX);
				}
			},
			false
		);

		itemsDiv.addEventListener(
			'touchend',
			(e) => {
				setTouchPositionX(null);
			},
			false
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (touchPositionX) {
			const itemsDiv = document.getElementById('orderItems');
			
			const rect = itemsDiv.getBoundingClientRect();
			const rectMiddle = rect.x + rect.width / 2;
			setIsHoldingRight(touchPositionX > rectMiddle);
		}
	}, [touchPositionX]);

	let {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemId = orderedItems[item.index].id;
	const itemData = itemsData.find((i) => {return i.id === itemId;});

	return (
		<div className={'OrderDndPreview'} style={style} >
			<div className={'OrderDndPreview-item' + (isHoldingRight ? ' right' : ' left')}>
				<span>{itemData ? itemData.text : null}</span>
			</div>
		</div>
	);
};

OrderDndPreview.propTypes = {
	orderedItems: PropTypes.array.isRequired,
	itemsData: PropTypes.array.isRequired,
};

export default OrderDndPreview;