const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2c-mc-pig-self-treatment',
		text: 'Må landmændene gerne foretage egenbehandling af grisene?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Ja, de må foretage egenbehandling, dog skal de informere slagteriet om det.',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Ja det må de gerne, men kun med tilladelse og aftale med besætningsdyrlægen.',
				isCorrect: true,
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm2c-mc-five-freedoms',
		text: 'Danish Crown krav til dyrevelfærd tager udgangspunkt i de Fem Friheder der er anbefalet og udviklet af Farm Animal Welfare, hvilke fem er det?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Frihed for tørst, sult og fejlernæring',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Frihed for medicinsk behandling'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Frihed for frygt og lidelser',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Frihed for tvangsfodring'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Frihed for ubehag',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Frihed for smerte, skader og sygdom',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Frihed for undertrykkelse af normal adfærd',
				isCorrect: true
			})
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}