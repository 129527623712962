const taskTemplates = require('data/templates/task-templates');

const moduleTasks = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm1f-mc-plant-based-focus',
		text: 'Hvorfor er der kommet mere og mere fokus på de plantebaserede produkter?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Der er mangel på animalske produkter og det er derfor nødvendigt at finde alternative fødevarer'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Plantebaserede fødevarer har et mindre klimaaftryk.',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Plantebaserede fødevarer har typisk meget lavt indhold af fedt og kolesterol.'
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		id: 'm1f-match-meat-product',
		text: 'Sæt udsagnet sammen med den forbrugerarketype, det passer til',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 1,
				option: 'De umiddelbare',
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 2,
				option: 'De bekvemme',
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 3,
				option: 'De traditionelle',
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				id: 4,
				option: 'De grønne',
				answers: [4]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Jeg foretrækker simpel mad og fastfood, for at minimere tiden brugt på madlavning.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Jeg vil hellere bruge min tid på oplevelser fremfor på madlavning.',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Jeg køber typisk supermarkedets egne billige produkter og giver ikke ekstra for kvalitetsprodukter',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Jeg går meget op i bæredygtighed og spiser vegetarretter minimum en gang om ugen.',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm1f-mc-pfood-waste',
		text: 'Den danske forbruger er blevet mere opmærksom på at undgå madspild, at spise mindre kød og undgå plastik. Hvorfor?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'COVID19-pandemien og prisstigningen på fødevarer har fået forbrugerne til at blive bevidste om overforbrug og spild',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Ønsket om en bæredygtig fødevareproduktion',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Pres fra den danske regering har fået forbrugerne til at være mere kritiske overfor hvad de putter på tallerkenen'
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'm1f-mc-income',
		text: 'Hvor stor en procentdel af indkomsten bruger den danske forbruger på fødevarer?',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '2%'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '6%'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '10%',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '20%'
			}),
		],
	}),
];


let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Check task id is defined and unique */
	if (task.id) {
    if (taskIdsArr.indexOf(task.id) >= 0) {
      console.error('Task id not unique: ', task.id);
    } else {
      taskIdsArr.push(task.id);
    }
  } else {
    console.error('Missing task id for task: ', task);
  }
});


export {
	moduleTasks
}