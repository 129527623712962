import {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './facilitator-delete-game-popup.scss';

const FacilitatorDeleteGamePopup = ({setShowDeleteGamePopup, deleteGame, setSelectedGameId}) => {
	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);

	/* Error msg */
	const [errMsg, setErrMsg] = useState(null);

	/**
	 * Delete a game and all its players
	 */
	const handleDeleteGame = () => {
		if (isLoading) return;

		setErrMsg(null);
		setIsLoading(true);
		deleteGame().then((result) => {
			if (result.status === 'success') {
				/* Game and players deleted, return to list of games */
				setSelectedGameId(null);
			} else {
				/* Error deleting game and players */
				console.error(result.error);
				setIsLoading(false);
				setErrMsg(getText('errorUiTexts', 'unknownError'));
			}
		});
	};

	return (
		<div className="FacilitatorDeleteGamePopup" onClick={() => {setShowDeleteGamePopup(false);}}>
			<div className="FacilitatorDeleteGamePopup-content" onClick={(e) => {e.stopPropagation();}} >
				{/* Header */}
				<div className="FacilitatorDeleteGamePopup-header">
					<div className="FacilitatorDeleteGamePopup-title">
						{getText('facilitatorUiTexts', 'deleteGame')}
					</div>
				</div>
				{/* Body */}
				<div className="FacilitatorDeleteGamePopup-body">
					{/* Text */}
					<div className="FacilitatorDeleteGamePopup-text">
						{renderMarkdown(getText('facilitatorUiTexts', 'deleteGamePopup'))}
					</div>
					{/* Error message */}
					<div className="FacilitatorDeleteGamePopup-errMsg">
						<span>{errMsg}</span>
					</div>
					{/* Buttons */}
					<div className="FacilitatorDeleteGamePopup-buttons">
						{/* Delete btn */}
						<div className="FacilitatorDeleteGamePopup-deleteBtn">
							<Button
								isLoading={isLoading}
								classes={['facilitator']}
								text={(getText('facilitatorUiTexts', 'yesDelete'))}
								onClick={() => {handleDeleteGame();}}
							/>
						</div>
						{/* Cancel btn */}
						<div className="FacilitatorDeleteGamePopup-cancelBtn">
							<Button
								classes={['facilitator']}
								text={(getText('facilitatorUiTexts', 'noCancel'))}
								onClick={() => {setShowDeleteGamePopup(false);}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

FacilitatorDeleteGamePopup.propTypes = {
	setShowDeleteGamePopup: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	setSelectedGameId: PropTypes.func.isRequired
};

export default FacilitatorDeleteGamePopup;